import input from "./Input";
import label from "./Label";
import select from "./Select";
import button from "./Button";
import header from "./Header";
import content from "./Content";
import textarea from "./Textarea";
import dropzone from "./Dropzone";
import footer from "./Footer";
import blank from "./Blank";
import stackChart from "./chart/stack";
import barChart from "./chart/bar";

export const Input = input;
export const Label = label;
export const Select = select;
export const Button = button;
export const Header = header;
export const Content = content;
export const Textarea = textarea;
export const Dropzone = dropzone;
export const Footer = footer;
export const StackChart = stackChart;
export const BarChart = barChart;
export const Blank = blank;

// export default {
//   Input,
//   Label,
//   Select,
// };
