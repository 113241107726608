import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { Bar } from "react-chartjs-2";

interface BarChartProps {
  data: Data;
  formatter: (value: any, context: any) => string;
  labelFormatter?: (value: any) => string;
  stepSize: number;
}

interface Dataset {
  label: string;
  data: number[];
  backgroundColor: string;
}

interface Data {
  labels: string[];
  datasets: Dataset[];
}

interface labelPropsT {
  datasetIndex?: number;
  xLabel?: number;
  value?: string;
}
interface labelPropsD {
  datasets?: Record<string, string>[];
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  formatter,
  labelFormatter,
  stepSize,
}) => {
  return (
    <div className="App">
      <Bar
        data={data}
        height={200}
        plugins={[ChartDataLabels]}
        options={{
          maintainAspectRatio: false,
          legend: {
            labels: {
              boxWidth: 12,
              fontSize: 14,
            },
          },
          tooltips: {
            mode: "single",
            callbacks: {
              label: function (t: labelPropsT, d: labelPropsD) {
                const subject = d?.datasets
                  ? d.datasets[t?.datasetIndex ? t.datasetIndex : 0]?.label
                  : "";
                const value = t?.value ? Number(t.value) : 0;
                const formattedValue = labelFormatter
                  ? labelFormatter(value)
                  : formatter(value, {});
                return `${subject}: ${formattedValue}`;
              },
            },
          },
          scales: {
            xAxes: [
              {
                categoryPercentage: 0.7,
                barPercentage: 0.7,
                gridLines: { display: false },
              },
            ],
            yAxes: [
              {
                categoryPercentage: 1.0,
                barPercentage: 1.0,
                gridLines: {
                  drawBorder: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                  min: 0,
                  max:
                    data.datasets
                      .map((el) => Math.max.apply(Math, el.data))
                      .reduce((p, v) => (p < v ? v : p)) + stepSize,
                  stepSize,
                },
              },
            ],
          },
          plugins: {
            datalabels: {
              anchor: "end",
              align: "end",
              offset: -2,
              color: "#000000",
              font: { size: 12 },
              formatter,
            },
          },
        }}
      />
    </div>
  );
};

export default BarChart;
