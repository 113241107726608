import { useQuery } from "@apollo/client";

import { GET_ME } from "../query/index";

export const useMe = () => {
  let { loading, error, data, refetch } = useQuery(GET_ME);

  if (loading) {
    return {
      state: "loading",
      user: null,
      refetch: null,
    };
  }

  if (error) {
    return {
      state: "error",
      user: null,
      refetch: null,
    };
  }

  if (!loading && !data.me) {
    return {
      state: "login required",
      user: null,
      refetch: null,
    };
  }
  return {
    state: "done",
    user: data ? data.me : {},
    refetch,
  };
};
