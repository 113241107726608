import React, { useState, useEffect } from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
import { SIGNUP_ATTENDEE, TOKEN_AUTH } from "../../query/index";
import { useMe } from "../../hooks/useMe";
import { User } from "../../interfaces/model";

import {
  Input,
  Label,
  Button,
  Header,
  Content,
  Select,
} from "../../components";
import "./index.css";

// const SuccessComponent: React.FC<RouteComponentProps> = ({ location }) => {
//   const { state, user } = useMe();

//   if (state === "error" || state === "login required") {
//     return <div className="absolute w-full h-screen z-30 bg-black opacity-50 flex items-center justify-center">
//         <div className="text-white text-2xl"> 에러가 발생했습니다. 회원가입을 다시 해주세요! </div>
//       </div>;
//   }

//   if (state === "loading") {
//     return (
//       <div className="absolute w-full h-screen z-30 bg-black opacity-50 flex items-center justify-center">
//         <div className="text-white text-2xl"> LOADING... </div>
//       </div>
//     );
//   }

//   return <Success user={user} location={location} />;
// };

// interface SuccessProps {
//   user: User;
//   location: any;
// }

const Success: React.FC = () => {
  // const [signupAttendee] = useMutation(SIGNUP_ATTENDEE);

  // useEffect(() => {
  //   const query = new URLSearchParams(location.search);
  //   try {
  //     signupAttendee({
  //       variables: {
  //         userEmail: user?.email,
  //         school: query.get('school'),
  //         year: query.get('year'),
  //         grade: query.get('grade'),
  //         klassNumber: query.get('knumber'),
  //         studentNumber: query.get('snumber')
  //       }
  //     })
  //   } catch(err) {
  //     setTimeout(() => signupAttendee({
  //     variables: {
  //       userEmail: user?.email,
  //       school: query.get('school'),
  //       year: query.get('year'),
  //       grade: query.get('grade'),
  //       klassNumber: query.get('knumber'),
  //       studentNumber: query.get('snumber')
  //     }
  //   }), 1000)
  //   }
  // }, [location])

  return (
    <div className="w-full box-border font-spoqa font-normal text-md">
      <Header middle={<>회원가입 완료</>} />

      <div className="w-full p-4">
        <div className="w-full h-screen-64 flex items-center justify-center">
          <div>
            <img src="img/hi-five.svg" alt="" className="mx-auto" />
            <div className="text-center text-2xl text-gray-1 font-gsans-medium mt-4">
              회원가입이 <br />
              완료되었습니다!
            </div>
          </div>
        </div>

        <Link to="/login">
          <Button className="mt-20 bg-orange-2 text-white">로그인</Button>
        </Link>
      </div>
    </div>
  );
};

export default Success;
