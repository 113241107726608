import React, { useState } from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
  SIGNUP_AUTH,
  GET_SIGNUP,
  SIGNUP_ATTENDEE,
  TOKEN_AUTH,
} from "../../query/index";

import {
  Input,
  Label,
  Button,
  Header,
  Content,
  Select,
  Blank,
} from "../../components";
import "./index.css";
import { Section } from "../../components/Section";
import { Checkbox } from "../../components/Checkbox";

function TransMessage(message: string) {
  if (message === "A user with that username already exists.") {
    return "입력된 이름을 가진 유저가 이미 존재합니다.";
  } else if (
    message === "User with this Email address already exists." ||
    message === "User with this 이메일 already exists."
  ) {
    return "입력된 이메일으로 가입된 유저가 이미 존재합니다.";
  } else if (message === "This password is entirely numeric.") {
    return "비밀번호가 숫자로만 이루어져 있습니다.";
  } else if (message === "This password is too short.") {
    return "비밀번호는 8자 이상이어야 합니다.";
  } else if (message === "This password is too common.") {
    return "비밀번호는 문자와 숫자를 조합해 8자 이상으로 작성해 주세요.";
  } else if (message === "This field is required.") {
    return "이 항목을 작성해 주세요.";
  } else if (message === "The two password fields didn’t match.") {
    return "비밀번호와 비밀번호 확인이 서로 다릅니다.";
  } else {
    return message;
  }
}

const Signup: React.FC<RouteComponentProps> = ({ history }) => {
  let { loading, error, data }: any = useQuery(GET_SIGNUP);

  let years: number[] = [];
  let grades: number[] = [];
  let knumbers: number[] = [];

  data?.allKlasses?.map((el: any) => {
    if (!years.includes(el.year)) {
      years.push(Number(el.year));
    }
    if (!grades.includes(el.grade)) {
      grades.push(Number(el.grade));
    }
    if (!knumbers.includes(el.klassNumber)) {
      knumbers.push(Number(el.klassNumber));
    }
  });

  const [register] = useMutation(SIGNUP_AUTH);
  const [tokenAuth] = useMutation(TOKEN_AUTH);
  const [signupAttendee] = useMutation(SIGNUP_ATTENDEE);

  const [isLoading, setLoading] = useState(false);

  const [isTeacher, setTeacher] = useState(false);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [school, setSchool] = useState();
  const [year, setYear] = useState();
  const [grade, setGrade] = useState();
  const [knumber, setKnumber] = useState();
  const [snumber, setSnumber] = useState();
  const [privacy, setPrivacy] = useState(false);
  const [privacy3, setPrivacy3] = useState(false);
  const [marketing, setMarketing] = useState(false);

  const [errors, setErrors] = useState();
  const [errorPublic, setErrorPublic] = useState("");

  const validateAndShowError = (field: string, value: any) => {
    if (field === "knumber" && value === 0) {
      if (errors && errors[field]) {
        let newErrors = errors ? JSON.parse(JSON.stringify(errors)) : {};
        delete newErrors[field];
        setErrors(newErrors);
      }
      return true;
    }
    if (!value || value.length === 0) {
      let newError = errors ? JSON.parse(JSON.stringify(errors)) : {};
      newError[field] = [
        {
          message: "이 항목을 채워주세요!",
        },
      ];
      setErrors(newError);
      return false;
    } else {
      if (errors && errors[field]) {
        let newErrors = errors ? JSON.parse(JSON.stringify(errors)) : {};
        delete newErrors[field];
        setErrors(newErrors);
      }
      return true;
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fields: Record<string, any> = {
      email,
      school,
      year,
      grade,
      knumber,
      phone: phone1 && phone2 && phone3,
    };
    if (!isTeacher) {
      fields.snumber = snumber;
      fields.nickname = nickname;
    }
    for (let field in fields) {
      if (!validateAndShowError(field, fields[field])) {
        return;
      }
    }
    try {
      setLoading(true);
      register({
        variables: {
          email,
          name,
          username: new Date().getTime(),
          nickname,
          phone: `${phone1}-${phone2}-${phone3}`,
          userType: isTeacher ? 3 : 0,
          password1,
          password2,
        },
      }).then((res) => {
        const { data } = res;
        console.log(data);
        if (data?.register?.success) {
          tokenAuth({
            variables: {
              email: email,
              password: password1,
            },
          })
            .then((res) => {
              const { data } = res;
              if (data?.tokenAuth?.success) {
                localStorage.setItem("token", data.tokenAuth.token);
                if (!isTeacher) {
                  signupAttendee({
                    variables: {
                      userEmail: email,
                      school,
                      year,
                      grade,
                      klassNumber: knumber,
                      studentNumber: snumber,
                    },
                  })
                    .then((res) => {
                      const { data } = res;
                      setLoading(false);
                      if (data?.signupAttendee?.attendee?.state) {
                        history.push("/report");
                      }
                    })
                    .catch((rej) => {
                      setLoading(false);
                      localStorage.setItem("error", JSON.stringify(rej));
                      alert(
                        "회원가입 완료! 입력된 학년/반 정보를 찾을 수 없습니다. 마이페이지에서 수정해주세요!",
                      );
                      history.push("/report");
                    });
                } else {
                  history.push("/report");
                }
              }
            })
            .catch((rej) => {
              setLoading(false);
              localStorage.setItem("error", JSON.stringify(rej));
            });
          // history.push(`/signup-success?school=${school}&year=${year}&grade=${grade}&knumber=${knumber}&snumber=${snumber}`);
        } else {
          setLoading(false);
          if (data?.register?.errors) {
            setErrors(data.register.errors);
          }
        }
      });
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div className="w-full box-border">
      {loading ? <Blank /> : <></>}
      {isLoading ? <Blank /> : <></>}
      {error ? <Blank text="접속 오류입니다. 다시 접속해 주세요!" /> : <></>}

      <Header
        left={
          <Link to="/">
            <div className="w-8 h-8 flex justify-center items-center">
              <img src="img/back.svg" alt="" />
            </div>
          </Link>
        }
        middle={<>회원가입</>}
      />

      <Content>
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <Label hfor="name">회원 타입</Label>

            <div className="w-full flex justify-between mt-1 ">
              <Button
                className={
                  isTeacher
                    ? "w-50%-3 rounded-lg bg-gray-8 text-gray-3.5"
                    : "w-50%-3 rounded-lg bg-orange-2 text-white"
                }
                onClick={(e) => {
                  e.preventDefault();
                  setTeacher(false);
                }}
              >
                학생
              </Button>
              <Button
                className={
                  isTeacher
                    ? "w-50%-3 rounded-lg bg-orange-2 text-white"
                    : "w-50%-3 rounded-lg bg-gray-8 text-gray-3.5"
                }
                onClick={(e) => {
                  e.preventDefault();
                  setTeacher(true);
                }}
              >
                선생님
              </Button>
            </div>
          </div>
          <div className="w-full mt-6">
            <Label hfor="name">이름</Label>
            <Input
              id="name"
              placeholder="이름을 입력해주세요"
              onChange={(e) => setName(e.target.value)}
            />
            <div className="text-pink-1 mt-1">
              {errors?.name
                ?.map((el: any) => TransMessage(el.message))
                .join(" ")}
            </div>
          </div>

          {isTeacher ? (
            ""
          ) : (
            <div className="w-full mt-6">
              <Label hfor="nickname">닉네임</Label>
              <Input
                id="nickname"
                placeholder="닉네임을 입력해주세요"
                onChange={(e) => setNickname(e.target.value)}
              />
              <div className="text-pink-1 mt-1">
                {errors?.nickname
                  ?.map((el: any) => TransMessage(el.message))
                  .join(" ")}
              </div>
            </div>
          )}

          <div className="w-full mt-6">
            <Label hfor="email">이메일</Label>
            <Input
              id="email"
              placeholder="이메일을 입력해주세요"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="text-pink-1 mt-1">
              {errors?.email
                ?.map((el: any) => TransMessage(el.message))
                .join(" ")}
            </div>
          </div>

          <div className="w-full mt-6">
            <Label hfor="password">비밀번호</Label>
            <Input
              type="password"
              id="password"
              placeholder="비밀번호(6자 이상)"
              onChange={(e) => setPassword1(e.target.value)}
            />
            <div className="text-pink-1 mt-1">
              {errors?.password1
                ?.map((el: any) => TransMessage(el.message))
                .join(" ")}
            </div>
            <Input
              type="password"
              placeholder="비밀번호 확인(6자 이상)"
              className="mt-3"
              onChange={(e) => setPassword2(e.target.value)}
            />
            <div className="text-pink-1 mt-1">
              {errors?.password2
                ?.map((el: any) => TransMessage(el.message))
                .join(" ")}
            </div>
          </div>

          <div className="w-full mt-6">
            <Label hfor="school">학교</Label>
            <Select
              id="school"
              placeholder="학교명을 선택해주세요"
              onChange={(e) => setSchool(e.target.value)}
            >
              {data?.allSchools?.map((el: any) => (
                <option value={el.name}>{el.name}</option>
              ))}
            </Select>
            <div className="text-pink-1 mt-1">
              {errors?.school
                ?.map((el: any) => TransMessage(el.message))
                .join(" ")}
            </div>
          </div>

          <div className="w-full flex justify-between mt-6">
            <div className="w-50%-3">
              <Label hfor="year">학년도</Label>
              <Select
                id="year"
                placeholder={"선택"}
                onChange={(e) => setYear(e.target.value)}
              >
                {years
                  ?.sort((a: number, b: number) => a - b)
                  .map((el: number, i: number) => (
                    <option key={i + el} value={el}>
                      {el}년도
                    </option>
                  ))}
              </Select>
              <div className="text-pink-1 mt-1">
                {errors?.year
                  ?.map((el: any) => TransMessage(el.message))
                  .join(" ")}
              </div>
            </div>

            <div className="w-50%-3">
              <Label hfor="grade">{isTeacher ? "담당 학년" : "학년"}</Label>
              <Select
                id="grade"
                placeholder="선택"
                onChange={(e) => setGrade(e.target.value)}
              >
                {grades
                  ?.sort((a: number, b: number) => a - b)
                  .map((el: number, i: number) => (
                    <option key={i + el} value={el}>
                      {el}
                    </option>
                  ))}
              </Select>
              <div className="text-pink-1 mt-1">
                {errors?.grade
                  ?.map((el: any) => TransMessage(el.message))
                  .join(" ")}
              </div>
            </div>
          </div>

          <div className="w-full flex justify-between mt-6">
            {isTeacher ? (
              <div className="w-50%-3">
                <Label hfor="knumber">담당 반</Label>
                <Select
                  id="knumber"
                  placeholder="선택"
                  onChange={(e) => setKnumber(e.target.value)}
                >
                  {knumbers
                    ?.sort((a: number, b: number) => a - b)
                    .map((el: number, i: number) => (
                      <option key={i + el} value={el}>
                        {el}
                      </option>
                    ))}
                </Select>

                <div className="text-pink-1 mt-1">
                  {errors?.knumber
                    ?.map((el: any) => TransMessage(el.message))
                    .join(" ")}
                </div>
              </div>
            ) : (
              <>
                <div className="w-50%-3">
                  <Label hfor="knumber">반</Label>
                  <Select
                    id="knumber"
                    placeholder="선택"
                    onChange={(e) => setKnumber(e.target.value)}
                  >
                    {knumbers
                      ?.sort((a: number, b: number) => a - b)
                      .map((el: number, i: number) => (
                        <option key={i + el} value={el}>
                          {el}
                        </option>
                      ))}
                  </Select>

                  <div className="text-pink-1 mt-1">
                    {errors?.knumber
                      ?.map((el: any) => TransMessage(el.message))
                      .join(" ")}
                  </div>
                </div>

                <div className="w-50%-3">
                  <Label hfor="snumber">번호</Label>
                  <Input
                    type="number"
                    id="snumber"
                    placeholder="선택"
                    onChange={(e) => setSnumber(e.target.value)}
                  />
                </div>

                <div className="text-pink-1 mt-1">
                  {errors?.snumber
                    ?.map((el: any) => TransMessage(el.message))
                    .join(" ")}
                </div>
              </>
            )}
          </div>

          <div className="w-full mt-6">
            <Label hfor="phone">핸드폰 번호</Label>
            <div className="w-full flex justify-between items-center mt-1">
              <Input
                type="number"
                id="phone"
                className="mr-2"
                onChange={(e) => setPhone1(e.target.value)}
              />
              -
              <Input
                type="number"
                className="mx-2"
                onChange={(e) => setPhone2(e.target.value)}
              />
              -
              <Input
                type="number"
                className="ml-2"
                onChange={(e) => setPhone3(e.target.value)}
              />
            </div>
            <div className="text-pink-1 mt-1">
              {errors?.phone
                ?.map((el: any) => TransMessage(el.message))
                .join(" ")}
            </div>
          </div>
          <Section spaceY="space-y-2">
            <div className="font-bold">
              <Checkbox
                label="전체 동의"
                onChange={() => {
                  const allChecked = privacy3 && privacy && marketing;
                  setPrivacy3(!allChecked);
                  setPrivacy(!allChecked);
                  setMarketing(!allChecked);
                }}
                checked={privacy3 && privacy && marketing}
              />
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="privacy"
                onChange={() => setPrivacy(!privacy)}
                checked={privacy}
              />
              <a href="https://superstudy.kr/31" target="_blank">
                <Label htmlFor="privacy">
                  <span className="font-semibold text-base cursor-pointer">
                    개인정보 수집 및 이용에 관한 동의(필수)
                  </span>
                </Label>
              </a>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="privacy3"
                onChange={() => setPrivacy3(!privacy3)}
                checked={privacy3}
              />
              <a href="https://superstudy.kr/32" target="blank">
                <Label htmlFor="privacy3">
                  <span className="font-semibold text-base cursor-pointer">
                    개인정보 제3자 제공에 관한 동의(필수)
                  </span>
                </Label>
              </a>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="marketing"
                onChange={() => setMarketing(!marketing)}
                checked={marketing}
              />
              <a href="https://superstudy.kr/33" target="blank">
                <Label htmlFor="marketing">
                  <span className="font-semibold text-base cursor-pointer">
                    홍보 및 마케팅을 위한 개인정보 제공 동의(선택)
                  </span>
                </Label>
              </a>
            </div>
          </Section>

          <Button className="mt-20 bg-orange-2 text-white" type="submit">
            회원가입
          </Button>
        </form>
      </Content>
    </div>
  );
};

export default withRouter(Signup);
