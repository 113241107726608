import React, { useState, useEffect } from "react";
import { User, Attendee } from "../../../interfaces/model";
import { useQuery, useMutation } from "@apollo/client";
import { GET_SIGNUP, UPDATE_USER } from "../../../query/index";
import { Label, Input, Button, Select } from "../../../components";
import "./index.css";

interface InfoUpdateProps {
  user: User;
  moveToRead: () => void;
}

const InfoUpdate: React.FC<InfoUpdateProps> = ({ user, moveToRead }) => {
  let { loading, error, data }: any = useQuery(GET_SIGNUP);

  const [updateUser] = useMutation(UPDATE_USER);

  let years: number[] = [];
  let grades: number[] = [];
  let knumbers: number[] = [];

  data?.allKlasses?.map((el: any) => {
    if (!years.includes(el.year)) {
      years.push(Number(el.year));
    }
    if (!grades.includes(el.grade)) {
      grades.push(Number(el.grade));
    }
    if (!knumbers.includes(el.klassNumber)) {
      knumbers.push(Number(el.klassNumber));
    }
  });

  const schools = data?.allSchools;
  const [isLoading, setLoading] = useState(false);
  const [schoolId, setSchoolId] = useState<number>(
    Number(user?.attendees[0]?.klass?.school?.id),
  );

  const attendees: Attendee[] = user?.attendees;
  // const klass = attendee?.klass;
  const klasses = user?.attendees?.map((el) => el.klass);

  const is2021 = attendees.filter(
    (attendee: Attendee) => attendee?.klass?.year === 2021,
  ).length;

  const [name, setName] = useState(user?.name);
  const [nickname, setNickname] = useState(user?.nickname);
  const [phone1, setPhone1] = useState(user?.phone?.split("-")[0]);
  const [phone2, setPhone2] = useState(user?.phone?.split("-")[1]);
  const [phone3, setPhone3] = useState(user?.phone?.split("-")[2]);

  const [_attendees, setAttendees] = useState<Attendee[]>(user?.attendees);
  const [newAttendee, setNewAttendee] = useState({
    studentNumber: undefined,
    klass: {
      grade: undefined,
      klassNumber: undefined,
      year: 2021,
    },
  });
  // const [year, setYear] = useState(klass?.year);
  // const [grade, setGrade] = useState(klass?.grade);
  // const [knumber, setKnumber] = useState(klass?.klassNumber);
  // const [snumber, setSnumber] = useState(attendee?.studentNumber);

  const [errorMessages, setErrorMessages] = useState<any>();
  const [errorMessagePublic, setErrorMessagePublic] = useState<any>();

  const validateAndShowError = (field: string, value: any) => {
    if (field === "knumber" && value === 0) {
      if (errorMessages && errorMessages[field]) {
        let newErrors = errorMessages
          ? JSON.parse(JSON.stringify(errorMessages))
          : {};
        delete newErrors[field];
        setErrorMessages(newErrors);
      }
      return true;
    }
    if (!value || value.length === 0) {
      let newError = errorMessages
        ? JSON.parse(JSON.stringify(errorMessages))
        : {};
      newError[field] = [
        {
          message: "이 항목을 채워주세요!",
        },
      ];
      setErrorMessages(newError);
      return false;
    } else {
      if (errorMessages && errorMessages[field]) {
        let newErrors = errorMessages
          ? JSON.parse(JSON.stringify(errorMessages))
          : {};
        delete newErrors[field];
        setErrorMessages(newErrors);
      }
      return true;
    }
  };

  const handleUpdateSubmit = (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const fields: Record<string, any> = {
        nickname,
        phone: phone1 && phone2 && phone3,
      };
      for (let field in fields) {
        if (!validateAndShowError(field, fields[field])) {
          return;
        }
      }
      const __attendees: any[] = _attendees;
      updateUser({
        variables: {
          name,
          nickname,
          userEmail: user.email,
          phone: `${phone1}-${phone2}-${phone3}`,
          schoolId,
          attendees: !is2021
            ? JSON.stringify([newAttendee].concat(__attendees))
            : JSON.stringify(_attendees),
        },
      })
        .then((res) => {
          const { data, errors } = res;
          if (data?.updateUser?.user?.name) {
            moveToRead();
          } else if (errors) {
            setErrorMessagePublic(errors[0].message);
          } else {
            alert("유저 정보를 변경하지 못했습니다.");
          }
          setLoading(false);
        })
        .catch((rej) => {
          console.log(rej);
          if (rej.graphQLErrors[0]?.message.includes("null value in column")) {
            setErrorMessagePublic(
              "해당하는 학년이나 반이 없습니다. 다시 한번 확인해주세요!",
            );
          } else if (rej.graphQLErrors[0]?.message) {
            setErrorMessagePublic(rej.graphQLErrors[0]?.message);
          }
        });
    } catch (err) {
      alert("오류가 발생했습니다. 잠시 후 다시 시도해 주세요!");
      setLoading(false);
    }
  };

  const handleChange = (
    updateData: any,
    klassUpdateData: any,
    attendeeId: string,
  ) => {
    setAttendees(
      _attendees.map((el: any) => {
        if (el.id === attendeeId) {
          return {
            ...el,
            ...updateData,
            klass: {
              ...el.klass,
              ...klassUpdateData,
            },
          };
        }
        return el;
      }),
    );
  };
  const handleNewChange = (updateData: any, klassUpdateData: any) => {
    setNewAttendee({
      ...newAttendee,
      ...updateData,
      klass: {
        ...newAttendee.klass,
        ...klassUpdateData,
      },
    });
  };

  console.log(schoolId);

  return (
    <>
      <div className="w-full mt-6">
        <Label hfor="name">이름</Label>
        <Input
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="text-pink-1 mt-1">
          {errorMessages?.name?.map((el: any) => el.message).join(" ")}
        </div>
      </div>

      <div className="w-full mt-6">
        <Label hfor="nickname">닉네임</Label>
        <Input
          id="nickname"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />
        <div className="text-pink-1 mt-1">
          {errorMessages?.nickname?.map((el: any) => el.message).join(" ")}
        </div>
      </div>

      <div className="w-full mt-6">
        <Label hfor="nickname">이메일</Label>
        <Input
          id="email"
          value={user?.email}
          className="text-gray-4"
          disabled
        />
        <div className="text-pink-1 mt-1">
          {errorMessages?.email?.map((el: any) => el.message).join(" ")}
        </div>
      </div>

      <div className="w-full mt-6">
        <Label hfor="phone">전화번호</Label>
        <div className="w-full flex justify-between items-center mt-1">
          <Input
            type="number"
            id="phone"
            className="mr-2"
            value={phone1}
            onChange={(e) => setPhone1(e.target.value)}
          />
          -
          <Input
            type="number"
            className="mx-2"
            value={phone2}
            onChange={(e) => setPhone2(e.target.value)}
          />
          -
          <Input
            type="number"
            className="ml-2"
            value={phone3}
            onChange={(e) => setPhone3(e.target.value)}
          />
        </div>
        <div className="text-pink-1 mt-1">
          {errorMessages?.phone?.map((el: any) => el.message).join(" ")}
        </div>
      </div>

      <div className="w-full mt-6">
        <Label hfor="school">학교</Label>
        <Select
          id="school"
          placeholder="선택"
          value={schoolId}
          onChange={(e) => setSchoolId(Number(e.target.value))}
        >
          {schools?.map((el: any, i: number) => (
            <option key={i + el.id} value={el.id} selected={el.id === schoolId}>
              {el.name}
            </option>
          ))}
        </Select>
      </div>

      {user?.attendees?.map((attendee: Attendee) => (
        <>
          <div className="w-full mt-6">
            <div className="font-bold text-lg">{attendee.klass?.year} 년도</div>
            <div className="text-pink-1 mt-1">
              {errorMessages?.year?.map((el: any) => el.message).join(" ")}
            </div>
          </div>
          <div className="w-full flex justify-between mt-2">
            <div className="w-1/3 px-2">
              <Label hfor="grade">학년</Label>
              <Select
                id="grade"
                placeholder="선택"
                style={{
                  color: attendee?.klass?.year !== 2021 ? "gray" : "black",
                }}
                onChange={(e) =>
                  handleChange(
                    {},
                    {
                      grade: Number(e.target.value),
                    },
                    attendee.id,
                  )
                }
                disabled={attendee?.klass?.year !== 2021}
              >
                {grades
                  ?.sort((a: number, b: number) => a - b)
                  .map((el: number, i: number) => (
                    <option
                      key={i + el}
                      value={el}
                      selected={attendee?.klass?.grade === el}
                    >
                      {el}
                    </option>
                  ))}
              </Select>
              <div className="text-pink-1 mt-1">
                {errorMessages?.grade?.map((el: any) => el.message).join(" ")}
              </div>
            </div>
            <div className="w-1/3 px-2">
              <Label hfor="knumber">반</Label>
              <Select
                id="knumber"
                placeholder="선택"
                style={{
                  color: attendee?.klass?.year !== 2021 ? "gray" : "black",
                }}
                onChange={(e) =>
                  handleChange(
                    {},
                    {
                      klassNumber: Number(e.target.value),
                    },
                    attendee.id,
                  )
                }
                disabled={attendee?.klass?.year !== 2021}
              >
                {knumbers
                  ?.sort((a: number, b: number) => a - b)
                  .map((el: number, i: number) => (
                    <option
                      key={i + el}
                      value={el}
                      selected={attendee?.klass?.klassNumber === el}
                    >
                      {el}
                    </option>
                  ))}
              </Select>

              <div className="text-pink-1 mt-1">
                {errorMessages?.knumber?.map((el: any) => el.message).join(" ")}
              </div>
            </div>

            <div className="w-1/3 px-2">
              <Label hfor="snumber">번호</Label>
              <Input
                type="number"
                id="snumber"
                style={{
                  color: attendee?.klass?.year !== 2021 ? "gray" : "black",
                }}
                placeholder="선택"
                defaultValue={
                  attendees.filter((el) => el.id === attendee.id)[0]
                    .studentNumber
                }
                onChange={(e) => {
                  handleChange(
                    {
                      studentNumber: Number(e.target.value),
                    },
                    {},
                    attendee.id,
                  );
                }}
                disabled={attendee?.klass?.year !== 2021}
              />
              <div className="text-pink-1 mt-1">
                {errorMessages?.snumber?.map((el: any) => el.message).join(" ")}
              </div>
            </div>
          </div>
        </>
      ))}

      {!is2021 && (
        <>
          <div className="w-full flex justify-between mt-6">
            <div className="w-50%-3">
              <Label hfor="year">학년도</Label>
              <Select
                id="year"
                placeholder={"선택"}
                style={{
                  color: "black",
                }}
                onChange={(e) =>
                  handleNewChange(
                    {},
                    {
                      year: Number(e.target.value),
                    },
                  )
                }
                disabled
              >
                {years
                  ?.sort((a: number, b: number) => a - b)
                  .map((el: number, i: number) => (
                    <option
                      key={i + el}
                      value={el}
                      selected={newAttendee?.klass?.year === el}
                    >
                      {el}년도
                    </option>
                  ))}
              </Select>
              <div className="text-pink-1 mt-1">
                {errorMessages?.year?.map((el: any) => el.message).join(" ")}
              </div>
            </div>

            <div className="w-50%-3">
              <Label hfor="grade">학년</Label>
              <Select
                id="grade"
                placeholder="선택"
                style={{
                  color: "black",
                }}
                onChange={(e) =>
                  handleNewChange(
                    {},
                    {
                      grade: Number(e.target.value),
                    },
                  )
                }
              >
                {grades
                  ?.sort((a: number, b: number) => a - b)
                  .map((el: number, i: number) => (
                    <option
                      key={i + el}
                      value={el}
                      selected={newAttendee?.klass?.grade === el}
                    >
                      {el}
                    </option>
                  ))}
              </Select>
              <div className="text-pink-1 mt-1">
                {errorMessages?.grade?.map((el: any) => el.message).join(" ")}
              </div>
            </div>
          </div>
          <div className="w-full flex justify-between mt-6">
            <div className="w-50%-3">
              <Label hfor="knumber">반</Label>
              <Select
                id="knumber"
                placeholder="선택"
                style={{
                  color: "black",
                }}
                onChange={(e) =>
                  handleNewChange(
                    {},
                    {
                      klassNumber: Number(e.target.value),
                    },
                  )
                }
              >
                {knumbers
                  ?.sort((a: number, b: number) => a - b)
                  .map((el: number, i: number) => (
                    <option
                      key={i + el}
                      value={el}
                      selected={newAttendee?.klass?.klassNumber === el}
                    >
                      {el}
                    </option>
                  ))}
              </Select>

              <div className="text-pink-1 mt-1">
                {errorMessages?.knumber?.map((el: any) => el.message).join(" ")}
              </div>
            </div>

            <div className="w-50%-3">
              <Label hfor="snumber">번호</Label>
              <Input
                type="number"
                id="snumber"
                style={{
                  color: "black",
                }}
                placeholder="선택"
                defaultValue={newAttendee.studentNumber}
                onChange={(e) => {
                  handleNewChange(
                    {
                      studentNumber: Number(e.target.value),
                    },
                    {},
                  );
                }}
              />
              <div className="text-pink-1 mt-1">
                {errorMessages?.snumber?.map((el: any) => el.message).join(" ")}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="text-pink-1 mt-1">{errorMessagePublic}</div>

      <Button
        className="mt-6 bg-gray-2 text-white mb-20"
        onClick={handleUpdateSubmit}
      >
        정보 변경하기
      </Button>
    </>
  );
};

export default InfoUpdate;
