import React, { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";

import { Button, Header, Content, Input, Label, Blank } from "../../components";
import { PASSWORD_RESET } from "../../query/index";
import { useMutation } from "@apollo/client";

interface MatchParams {
  token: string;
}

function TransMessage(message: string) {
  if (message === "A user with that username already exists.") {
    return "입력된 이름을 가진 유저가 이미 존재합니다.";
  } else if (
    message === "User with this Email address already exists." ||
    message === "User with this 이메일 already exists."
  ) {
    return "입력된 이메일으로 가입된 유저가 이미 존재합니다.";
  } else if (message === "This password is entirely numeric.") {
    return "비밀번호가 숫자로만 이루어져 있습니다.";
  } else if (
    message === "This password is too short." ||
    message ===
      "This password is too short. It must contain at least 8 characters."
  ) {
    return "비밀번호는 8자 이상이어야 합니다.";
  } else if (message === "This password is too common.") {
    return "비밀번호는 문자와 숫자를 조합해 8자 이상으로 작성해 주세요.";
  } else if (message === "This field is required.") {
    return "이 항목을 작성해 주세요.";
  } else if (message === "The two password fields didn’t match.") {
    return "비밀번호와 비밀번호 확인이 서로 다릅니다.";
  } else {
    return message;
  }
}

const ResetPassword: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  let { token } = match.params;
  const [passwordReset] = useMutation(PASSWORD_RESET);
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [errors, setErrors] = useState<any>();
  const [errMessage, setErrMessage] = useState<any>("");

  const handleResetPassword = () => {
    passwordReset({
      variables: {
        token,
        newPassword1,
        newPassword2,
      },
    })
      .then((res) => {
        const { data } = res;
        setLoading(false);
        if (data?.passwordReset?.success) {
          setSuccess(true);
        } else if (data?.passwordReset?.errors) {
          setSuccess(false);
          setErrors(data.passwordReset.errors);
        } else {
          setSuccess(false);
        }
      })
      .catch((rej) => {
        setLoading(false);
        setSuccess(false);
        setErrMessage(
          "현재 유저 인증에 오류가 있는 상태입니다. 관리자에게 문의해 주세요!",
        );
      });
  };
  return (
    <div className="w-full box-border">
      {isLoading ? <Blank /> : <></>}
      <Header middle={<div className="min-w-max">비밀번호 변경</div>} />
      <Content>
        {isSuccess ? (
          <div className="w-full p-4">
            <div className="w-full h-screen-64 flex items-center justify-center">
              <div>
                <div className="text-center text-2xl text-gray-1 font-bold font-spoqa">
                  <div className="inline-block">비밀번호 변경이</div>{" "}
                  <div className="inline-block">완료되었습니다.</div>
                </div>
                <div className="text-center text-lg text-gray-3 font-spoqa mt-2">
                  <div className="inline-block">새로운 비밀번호로</div>{" "}
                  <div className="inline-block"> 다시 로그인해주세요.</div>
                </div>
              </div>
            </div>
            <Link to="/login">
              <Button className="mt-20 bg-orange-2 text-white">로그인</Button>
            </Link>
          </div>
        ) : (
          <>
            <div className="w-full mt-4">
              <div className="mx-auto text-center font-bold font-spoqa text-xl">
                새로운 비밀번호를 설정해주세요
              </div>
            </div>

            <div className="w-full mt-14">
              <Label hfor="newPassword1">새로운 비밀번호</Label>
              <Input
                id="newPassword1"
                type="password"
                relative_path="../"
                placeholder="새로운 비밀번호를 입력해주세요"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNewPassword1(e.target.value);
                }}
              />
              <div className="text-pink-1 mt-1">
                {errors?.newPassword1
                  ?.map((el: any) => TransMessage(el.message))
                  .join(" ")}
              </div>
            </div>

            <div className="w-full mt-6">
              <Label hfor="newPassword2">새로운 비밀번호 재입력</Label>
              <Input
                id="newPassword2"
                type="password"
                relative_path="../"
                placeholder="새로운 비밀번호를 재입력해주세요"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNewPassword2(e.target.value);
                }}
              />
              <div className="text-pink-1 mt-1">
                {errors?.newPassword2
                  ?.map((el: any) => TransMessage(el.message))
                  .join(" ")}
              </div>
            </div>
            <div className="text-pink-1 mt-1">{errMessage}</div>
            <Button
              className={
                newPassword1 && newPassword2
                  ? "mt-6 border border-black bg-white rounded-lg text-black"
                  : "mt-6 border border-gray-7 bg-white text-gray-4"
              }
              disabled={(!newPassword1 && !newPassword2) || isSuccess}
              type="submit"
              onClick={handleResetPassword}
            >
              비밀번호 변경하기
            </Button>
          </>
        )}
      </Content>
    </div>
  );
};

export default ResetPassword;
