import React, { useState, InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  relative_path?: string;
}

const Input: React.FC<InputProps> = ({
  className,
  inputClassName,
  children,
  relative_path,
  ...props
}) => {
  if (!relative_path) {
    relative_path = "";
  }
  const [isVisible, setVisible] = useState(false);
  return (
    <div
      className={`p-4 flex justify-between rounded-lg bg-gray-8 my-1 border-2 border-gray-7 focus-within:border-orange-2 ${className}`}
    >
      <input
        className={`w-full outline-none bg-gray-8 flex items-center text-lg focus:bg-gray-8 placeholder-gray-4 pr-1 ${inputClassName}`}
        {...props}
        type={
          props.type === "password"
            ? isVisible
              ? "text"
              : "password"
            : props.type
        }
      />
      {props.type === "password" ? (
        isVisible ? (
          <img
            onClick={(e) => setVisible(false)}
            className="w-6 h-6"
            src={`${relative_path}img/visibility.svg`}
            alt=""
          />
        ) : (
          <img
            onClick={(e) => setVisible(true)}
            className="w-6 h-6"
            src={`${relative_path}img/invisibility.svg`}
            alt=""
          />
        )
      ) : (
        <></>
      )}
      {children}
    </div>
  );
};

export default Input;
