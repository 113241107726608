import React from "react";
import { useDropzone } from "react-dropzone";

interface MyDropzoneInput {
  className?: string;
  onDrop: any;
}

const MyDropzone: React.FC<MyDropzoneInput> = ({
  className,
  onDrop,
  children,
}) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className={`max-w-max ${className}`}>
      <input {...getInputProps()} accept="image/*" />
      {children}
    </div>
  );
};

export default MyDropzone;
