import React from "react";

interface ContentProps {
  className?: string;
}

const Content: React.FC<ContentProps> = ({ className, children }) => {
  if (!className) {
    className = "";
  }
  return (
    <div
      className={`w-full font-spoqa font-normal text-md relative ${className}`}
    >
      <div className="max-w-128 m-auto px-0 sl:px-5 py-5 relative">
        {children}
      </div>
    </div>
  );
};

export default Content;
