import React, { ReactElement } from "react";

interface HeaderProps {
  left?: ReactElement;
  middle?: ReactElement;
  right?: ReactElement;
}

const Header: React.FC<HeaderProps> = ({ left, middle, right }) => {
  return (
    <div className="w-full flex justify-between text-center p-4 font-spoqa font-bold text-gray-1 text-lg border-b-2 border-gray-7">
      <div className="w-1/3 flex justify-start md:justify-center items-center flex-row p-1">
        {left}
      </div>
      <div className="w-1/3 flex justify-center items-center flex-row p-1">
        {middle}
      </div>
      <div className="w-1/3 flex justify-end md:justify-center items-center flex-row p-1">
        {right}
      </div>
    </div>
  );
};

export default Header;
