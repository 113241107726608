import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import Viewer from "react-viewer";

import { GET_ADMIN, UPDATE_REPORT_FEEDBACK } from "../../query/index";
import { User } from "../../interfaces/model/index";
import { Button, Header, Textarea, Blank } from "../../components";

import { API_URL } from "../../API_URL";
import StatisticsModal from "./statisticsModal";
import "./index.css";

interface TeacherAdminProps {
  history: any;
  user?: User;
}

function popup(url: string) {
  window.open(url);
}

const TeacherAdmin: React.FC<TeacherAdminProps> = ({ user, history }) => {
  let { loading, error, data, refetch }: any = useQuery(GET_ADMIN);
  const [updateReportFeedback] = useMutation(UPDATE_REPORT_FEEDBACK);

  const [isLoading, setLoading] = useState(false);
  const [isSubmitModalOpen, openSubmitModal] = useState(false);
  const [isImageModalOpen, openImageModal] = useState(false);
  const [isStatisticsModalOpen, setStatisticsModalOpen] = useState(false);

  const [weeksAgo, setWeeksAgo] = useState(0);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD"),
  );
  const [selectedKlassId, setSelectedKlassId] = useState(-1);
  const [selectedStudentId, setSelectedStudentId] = useState(-1);

  const [feedback, setFeedback] = useState("");
  const [comment, setComment] = useState("");

  let today = moment().subtract(weeksAgo, "weeks");
  let startDay = today.clone().startOf("isoWeek").subtract(1, "days");
  let endDay = today.clone().endOf("isoWeek").subtract(1, "days");

  let WeekString =
    startDay.format("YYYY.MM.DD") + " - " + endDay.format("YYYY.MM.DD");

  const selectedStudent =
    data?.allKlasses[selectedKlassId]?.attendees[selectedStudentId]?.user;

  let selectedReport: any = data?.allKlasses[selectedKlassId]?.attendees[
    selectedStudentId
  ]?.report.reduce(
    (acc: any, cur: any) => {
      if (cur.date === selectedDate) {
        return cur;
      } else {
        return acc;
      }
    },
    { date: undefined },
  );

  useEffect(() => {
    selectedReport = data?.allKlasses[selectedKlassId]?.attendees[
      selectedStudentId
    ]?.report.reduce(
      (acc: any, cur: any) => {
        if (cur.date === selectedDate) {
          return cur;
        } else {
          return acc;
        }
      },
      { date: undefined },
    );
    setFeedback(selectedReport?.feedback || "");
    setComment(selectedReport?.comment || "");
  }, [selectedKlassId, selectedStudentId]);

  const handleFeedbackSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setLoading(true);
    updateReportFeedback({
      variables: {
        id: selectedReport?.id,
        feedback,
      },
    }).then((res) => {
      const { data } = res;
      if (data.updateReportFeedback.report) {
        refetch();
      }
      setLoading(false);
      openSubmitModal(true);
    });
  };

  const logout = () => {
    localStorage.removeItem("token");
    history.push("/");
  };

  return (
    <div className="w-full box-border font-spoqa font-normal text-md">
      <Header
        left={
          <div className="text-gray-1 text-2xl font-spoqa">SUPER SPACE</div>
        }
        middle={
          <div className="text-gray-1 text-lg font-spoqa">피드백 관리</div>
        }
        right={
          <div className="flex justify-center items-center">
            <Button className="inline min-w-max py-3 px-6" onClick={logout}>
              로그아웃
            </Button>
          </div>
        }
      />

      <div className="w-full bg-gray-8 pt-12 font-spoqa">
        {isLoading || loading ? <Blank /> : <></>}
        {error ? <Blank text="접속 오류입니다. 다시 접속해 주세요!" /> : <></>}
        {isSubmitModalOpen && (
          <div className="absolute w-full inset-0 z-30 bg-black bg-opacity-75 flex items-center justify-center">
            {/* Icons made by <a href="https://www.flaticon.com/authors/roundicons" title="Roundicons">Roundicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a> */}
            <div className="w-120 h-64 flex items-center justify-center bg-white rounded-xl relative">
              <img
                className="absolute top-6 right-6 w-8 h-8 cursor-pointer"
                src="img/close-btn.svg"
                onClick={() => openSubmitModal(false)}
              />
              <div className="w-64 text-center">
                <div className="font-bold text-xl mt-4 mb-6 font-spoqa">
                  제출이 완료되었습니다!
                </div>
                <Button
                  className="text-white bg-orange-2"
                  onClick={() => openSubmitModal(false)}
                >
                  확인
                </Button>
              </div>
            </div>
          </div>
        )}
        {isStatisticsModalOpen && (
          <StatisticsModal
            useremail={selectedStudent?.email}
            username={selectedStudent?.name}
            grade={data?.me?.klasses[selectedKlassId]?.grade}
            onClose={() => setStatisticsModalOpen(false)}
          />
        )}
        <div className="container mx-auto">
          <div className="max-w-192 mx-auto">
            <div className="flex items-center justify-between">
              <div
                className="cursor-pointer w-10 h-10 flex items-center justify-center"
                onClick={() => setWeeksAgo(weeksAgo + 1)}
              >
                <img className="w-5 h-5" src="img/left-arrow.svg" />
              </div>
              <div className="text-gray-1 font-bold text-1.5xl font-apneo">
                {WeekString}
              </div>
              <div
                className="cursor-pointer w-10 h-10 flex items-center justify-center"
                onClick={() => setWeeksAgo(weeksAgo - 1)}
              >
                <img className="w-5 h-5" src="img/right-arrow.svg" />
              </div>
            </div>

            <div className="flex justify-around mt-5">
              {[
                [0, "일"],
                [1, "월"],
                [2, "화"],
                [3, "수"],
                [4, "목"],
                [5, "금"],
                [6, "토"],
              ].map(([num, day]) => {
                return (
                  <div
                    key={day}
                    className={
                      moment(startDay).add(num, "days").format("YYYY-MM-DD") ===
                      selectedDate
                        ? "bg-gray-2 text-white rounded-lg text-center w-1/8 py-2 cursor-pointer"
                        : "bg-white-5 rounded-lg text-center w-1/8 py-2 cursor-pointer"
                    }
                    onClick={(e) => {
                      setSelectedDate(
                        moment(startDay).add(num, "days").format("YYYY-MM-DD"),
                      );
                      setSelectedStudentId(-1);
                      setFeedback("");
                      setComment("");
                    }}
                  >
                    <div>{moment(startDay).add(num, "days").date()}</div>
                    <div className="text-sm">{day}요일</div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="w-full p-4">
            <div className="text-xl font-bold font-apneo my-4">
              코멘트 작성하기
            </div>
            <div className="w-full flex">
              <div className="w-3/5 mr-2 border border-gray-5 rounded-lg bg-white">
                <div className="text-lg px-4 py-3 font-apneo font-bold">
                  <div className="w-full flex justify-between items-center">
                    {selectedStudent?.name || (
                      <span className="text-transparent">STUDENTS</span>
                    )}
                  </div>
                  {selectedStudent?.name && (
                    <div
                      className="px-4 py-1 border border-orange-1 rounded-lg flex items-center justify-center hover:bg-orange-5 cursor-pointer"
                      onClick={() => setStatisticsModalOpen(true)}
                    >
                      <div className="font-normal text-base text-orange-1">
                        통계 보기
                      </div>
                    </div>
                  )}
                </div>
                <div className="relative w-full h-0 overflow-hidden pb-3/5">
                  <div
                    className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover rounded-lg"
                    style={{
                      backgroundImage: `url(${API_URL}${selectedReport?.image})`,
                    }}
                    onClick={
                      selectedReport?.image
                        ? () => openImageModal(true)
                        : () => {}
                    }
                  ></div>
                </div>
                <Viewer
                  visible={isImageModalOpen}
                  images={[
                    {
                      src: selectedReport?.image
                        ? `${API_URL}${selectedReport?.image}`
                        : "",
                      alt: "",
                    },
                  ]}
                  onClose={() => openImageModal(false)}
                />
              </div>
              <div className="w-2/5 ml-2 border border-gray-5 p-4 rounded-lg bg-white">
                <div className="flex justify-between mb-2">
                  <div className="text-gray-3 font-bold">학생 코멘트</div>
                  <div className="text-orange-1 font-bold font-apneo">제출</div>
                </div>
                <Textarea
                  className="bg-gray-8 border-gray-7"
                  value={comment}
                  disabled
                />
                <div className="flex justify-between mt-5 mb-3">
                  <div className="text-gray-3 font-bold">수동 피드백 입력</div>
                  {selectedReport?.feedback ? (
                    <div className="text-gray-3 font-bold font-apneo">
                      피드백 완료
                    </div>
                  ) : (
                    <div className="text-orange-1 font-bold font-apneo">
                      피드백 대기
                    </div>
                  )}
                </div>
                <Textarea
                  className="text-base text-gray-1 bg-white border-gray-7"
                  placeholder="피드백을 입력해주세요."
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                />

                <Button
                  className={
                    feedback && selectedReport
                      ? "mt-4 bg-orange-2 text-white cursor-pointer"
                      : "mt-4 bg-gray-7 text-gray-4 cursor-pointer"
                  }
                  onClick={handleFeedbackSubmit}
                  disabled={!(feedback && selectedReport)}
                >
                  제출
                </Button>
              </div>
            </div>
          </div>

          <div className="w-full p-4 mb-24">
            <div className="text-xl font-bold font-apneo my-4">우리반 학생</div>
            <div className="w-full -mx-2 h-128 overflow-y-scroll">
              {data?.allKlasses?.map((klass: any, ki: number) => (
                <div className="my-6">
                  <div>
                    {klass.grade}학년 {klass.klassNumber}반
                  </div>
                  {klass.attendees?.map((student: any, si: number) => (
                    <div
                      className="inline-block rounded border border-gray-5 m-2 hover:shadow"
                      onClick={(e) => {
                        setSelectedKlassId(ki);
                        setSelectedStudentId(si);
                      }}
                    >
                      <div className="flex justify-between font-apneo p-2 border-b border-gray-4">
                        <div className="font-bold text-md">
                          {student.user.name}
                        </div>
                        <div className="font-bold text-sm text-orange-1">
                          {student.report.reduce(
                            (acc: any, cur: any) => {
                              if (cur.date === selectedDate) {
                                return cur;
                              } else {
                                return acc;
                              }
                            },
                            { date: undefined },
                          ).date
                            ? "제출"
                            : "미제출"}
                        </div>
                      </div>
                      {student.report.reduce(
                        (acc: any, cur: any) => {
                          if (cur.date === selectedDate) {
                            return cur;
                          } else {
                            return acc;
                          }
                        },
                        { date: undefined },
                      ).feedback ? (
                        <div className="font-bold text-sm text-gray-3 py-3 px-4">
                          피드백 완료
                        </div>
                      ) : (
                        <div className="font-bold text-sm text-orange-1 py-3 px-4">
                          피드백 대기
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* footer */}
        <div className="w-full py-12 bg-black text-white flex justify-around">
          <div className="w-1/4 font-bold text-xl text-center">SUPERSTUDY</div>
          <div className="w-1/2">
            <div className="flex text-md">
              <div className="mr-4">Work</div>
              <div className="mx-4">Services</div>
              <div className="mx-4">Contact</div>
            </div>
            <div className="text-sm mt-4">
              Copyright © 2020 SUPER STUDY. All Rights Reserved
            </div>
          </div>
          <div className="w-1/4 flex justify-center">
            <div className="w-8 h-8 border border-white flex justify-center items-center mr-2">
              <img src="img/facebook.svg"></img>
            </div>
            <div className="w-8 h-8 border border-white flex justify-center items-center">
              <img src="img/instagram.svg"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherAdmin;
