import React, { useState, useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";

import { Button, Header, Content, Blank } from "../../components";
import { VERIFY_ACCOUNT } from "../../query/index";
import { useMutation } from "@apollo/client";

interface MatchParams {
  token: string;
}

const VerifyAccount: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  let { token } = match.params;
  const [verifyAccount] = useMutation(VERIFY_ACCOUNT);
  const [isLoading, setLoading] = useState(true);
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    verifyAccount({
      variables: {
        token,
      },
    })
      .then((res) => {
        const { data } = res;
        setLoading(false);
        if (data?.verifyAccount?.success) {
          setSuccess(true);
        } else if (
          data?.verifyAccount?.errors?.nonFieldErrors[0]?.code ===
          "already_verified"
        ) {
          setSuccess(true);
        } else {
          setSuccess(false);
        }
      })
      .catch((rej) => {
        setLoading(false);
        setSuccess(false);
      });
  }, [token]);
  return (
    <div className="w-full box-border">
      <Header middle={<>계정 인증</>} />
      <Content>
        {isLoading ? <Blank /> : <></>}
        {isSuccess ? (
          <div className="w-full p-4">
            <div className="w-full h-screen-64 flex items-center justify-center">
              <div>
                <img src="../img/hi-five.svg" alt="" className="mx-auto" />
                <div className="text-center text-2xl text-gray-1 font-gsans-medium mt-4">
                  회원가입이 <br />
                  완료되었습니다!
                </div>
              </div>
            </div>
            <Link to="/login">
              <Button className="mt-20 bg-orange-2 text-white">로그인</Button>
            </Link>
          </div>
        ) : (
          <div className="w-full p-4">
            <div className="w-full h-screen-64 flex items-center justify-center">
              <div>
                <div className="text-center text-2xl text-gray-1 font-gsans-medium mt-4">
                  토큰이 만료되었거나, 유효하지 않은 토큰입니다!
                </div>
                <Link to="/mypage">
                  <Button className="mt-20 bg-orange-2 text-white">
                    인증 다시하기
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </Content>
    </div>
  );
};

export default VerifyAccount;
