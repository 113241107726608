import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import { Header, Content, Footer } from "../../components";

const Mypage: React.FC<RouteComponentProps> = ({ history }) => {
  const logout = () => {
    localStorage.removeItem("token");
    history.push("/");
  };

  return (
    <div className="w-full box-border">
      <Header middle={<>마이페이지</>} />

      <Content>
        <Link to="/mypage/info">
          <div className="flex justify-between items-center py-6 border-b-2 border-gray-6 cursor-pointer">
            <div className="text-gray-1 font-sfpro font-bold">내 정보</div>
            <img src="img/right-arrow.svg" alt="" />
          </div>
        </Link>
        <Link to="/notices">
          <div className="flex justify-between items-center py-6 border-b-2 border-gray-6 cursor-pointer">
            <div className="text-gray-1 font-sfpro font-bold">공지사항</div>
            <img src="img/right-arrow.svg" alt="" />
          </div>
        </Link>
        <div className="flex justify-between items-center py-6 border-b-2 border-gray-6 cursor-pointer">
          <div className="text-gray-1 font-sfpro font-bold">이용약관</div>
          <img src="img/right-arrow.svg" alt="" />
        </div>
        <div className="flex justify-between items-center py-6 border-b-2 border-gray-6 cursor-pointer">
          <div className="text-gray-1 font-sfpro font-bold">
            개인정보취급방침
          </div>
          <img src="img/right-arrow.svg" alt="" />
        </div>
        <div
          className="flex justify-between items-center py-6 border-b-2 border-gray-6 cursor-pointer"
          onClick={logout}
        >
          <div className="text-gray-1 font-sfpro font-bold">로그아웃</div>
          <img src="img/right-arrow.svg" alt="" />
        </div>
      </Content>

      <Footer selected="mypage" />
    </div>
  );
};

export default withRouter(Mypage);
