import React from "react";
import { Link } from "react-router-dom";

import { Header, Content, Footer } from "../../../components";
import { GET_NOTICES_TITLE } from "../../../query/index";
import { useQuery } from "@apollo/client";

const Notices: React.FC = () => {
  const { loading, error, data } = useQuery(GET_NOTICES_TITLE);

  return (
    <div>
      <Header
        left={
          <Link to="/mypage">
            <div className="w-6 h-6 flex justify-center items-center">
              <img src="../img/back.svg" alt="" />
            </div>
          </Link>
        }
        middle={<>공지사항</>}
      />
      <Content>
        {data?.allNotices?.map((el: any) => (
          <Link to={`/notices/${el.id}`}>
            <div className="flex justify-between items-center pt-3 pb-6 border-b-2 border-gray-6 cursor-pointer">
              <div className="text-gray-1 font-sfpro font-bold">{el.title}</div>
              <div className="text-gray-3 font-sfpro text-sm">
                {el.createdAt}
              </div>
            </div>
          </Link>
        ))}
      </Content>
      <Footer selected="mypage" relative_path="../" />
    </div>
  );
};

export default Notices;
