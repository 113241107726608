import React, { LabelHTMLAttributes } from "react";

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  hfor?: string;
  className?: string;
}

const Label: React.FC<LabelProps> = ({
  hfor,
  className,
  children,
  ...props
}) => {
  return (
    <label
      htmlFor={hfor}
      className={`text-gray-2 mb-1 ${className}`}
      {...props}
    >
      {children}
    </label>
  );
};

export default Label;
