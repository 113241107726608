import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMe } from "../hooks/useMe";

import { Button } from "../components";

const Home: React.FC = () => {
  const [isDarkMode, setDarkMode] = useState(false);
  const { state, user } = useMe();

  return (
    <div
      className={
        isDarkMode
          ? "w-full h-screen box-border font-spoqa font-normal text-md bg-black"
          : "w-full h-screen box-border font-spoqa font-normal text-md"
      }
    >
      <div className="h-full max-w-128 m-auto px-0 sl:px-5 py-5">
        <div className="h-screen-64 flex items-center justify-center">
          {isDarkMode ? (
            <div
              className="w-full h-full bg-center bg-no-repeat bg-cover "
              style={{ backgroundImage: "url('img/superstudy-dark.png')" }}
            >
              <img className="mx-auto w-64" src="img/logo.svg" alt="" />
            </div>
          ) : (
            <div>
              <img className="mx-auto w-64" src="img/logo.svg" />
              <img className="mx-auto" src="img/superstudy-light.png" />
            </div>
          )}
        </div>
        <div>
          {user ? (
            user.userType === "A_1" ? (
              <Link to="/teacher">
                <Button className="z-20 block bg-orange-2 text-white">
                  선생님 페이지
                </Button>
              </Link>
            ) : (
              <Link to="/report">
                <Button className="z-20 block bg-orange-2 text-white">
                  시간관리장
                </Button>
              </Link>
            )
          ) : (
            <>
              <Link to="/login">
                <Button className="z-20 block border-2 border-orange-2 bg-white text-orange-2">
                  로그인
                </Button>
              </Link>
              <Link to="/signup">
                <Button className="mt-4 bg-orange-2 text-white">
                  회원가입
                </Button>
              </Link>
            </>
          )}
          {/* <div className={isDarkMode ? "text-white mt-4" : "text-gray-1 mt-4"}>
            최초 로그인 시{" "}
            <a
              href="#"
              className={
                isDarkMode ? "underline text-white" : "underline text-gray-1"
              }
            >
              서비스 약관
            </a>{" "}
            및{" "}
            <a
              href="#"
              className={
                isDarkMode ? "underline text-white" : "underline text-gray-1"
              }
            >
              개인정보처리 방침
            </a>
            에 동의한 것으로 인정됩니다.
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
