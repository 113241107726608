import React from "react";
import { Link } from "react-router-dom";

interface FooterProps {
  selected: "report" | "statistics" | "mypage";
  relative_path?: string;
}

const Footer: React.FC<FooterProps> = ({ selected, relative_path }) => {
  if (!relative_path) {
    relative_path = "";
  }
  return (
    <div className="fixed bottom-0 w-full">
      <div className="w-full h-full bg-gray-6 flex flex-between font-sfpro p-2">
        <div className="w-1/3 text-center ">
          <Link to="/report">
            <div
              className={
                selected === "report"
                  ? "w-full h-full cursor-pointer text-orange-1"
                  : "w-full h-full cursor-pointer text-gray-3"
              }
            >
              <img
                className="mx-auto w-6 h-6 mb-2 text-gray-4"
                src={
                  selected === "report"
                    ? `${relative_path}img/planner-icon-selected.svg`
                    : `${relative_path}img/planner-icon.svg`
                }
                alt=""
              />
              플래너
            </div>
          </Link>
        </div>
        <div className="w-1/3 text-center">
          <Link to="/statistics">
            <div
              className={
                selected === "statistics"
                  ? "w-full h-full cursor-pointer text-orange-1"
                  : "w-full h-full cursor-pointer text-gray-3"
              }
            >
              <img
                className="mx-auto w-6 h-6 mb-2"
                src={
                  selected === "statistics"
                    ? `${relative_path}img/statistics-icon-selected.svg`
                    : `${relative_path}img/statistics-icon.svg`
                }
                alt=""
              />
              통계
            </div>
          </Link>
        </div>
        <div className="w-1/3 text-center">
          <Link to="/mypage">
            <div
              className={
                selected === "mypage"
                  ? "w-full h-full cursor-pointer text-orange-1"
                  : "w-full h-full cursor-pointer text-gray-3"
              }
            >
              <img
                className="mx-auto w-6 h-6 mb-2"
                src={
                  selected === "mypage"
                    ? `${relative_path}img/mypage-icon-selected.svg`
                    : `${relative_path}img/mypage-icon.svg`
                }
                alt=""
              />
              마이페이지
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
