import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDetailMe } from "../../../hooks/useDetailMe";

import { Header, Content, Footer, Blank } from "../../../components";

import InfoRead from "./read";
import InfoUpdate from "./update";

const MypageInfo: React.FC = () => {
  const { state, user, refetch } = useDetailMe();
  const [readState, setReadState] = useState(true);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [readState, refetch]);

  if (state === "error" || state === "login required") {
    alert("로그인이 필요한 접근 주소입니다.");
    return <Redirect to="/login"></Redirect>;
  }

  return (
    <div className="w-full box-border">
      {state === "loading" ? <Blank /> : ""}
      <Header
        left={
          <Link to="/mypage">
            <div className="w-6 h-6 flex justify-center items-center">
              <img src="../img/back.svg" alt="" />
            </div>
          </Link>
        }
        middle={<>내 정보</>}
      />
      <Content>
        {readState ? (
          <InfoRead moveToUpdate={() => setReadState(false)} user={user} />
        ) : (
          <InfoUpdate moveToRead={() => setReadState(true)} user={user} />
        )}
      </Content>
      <Footer selected="mypage" relative_path="../" />
    </div>
  );
};

export default MypageInfo;
