import React from "react";

interface BlankProps {
  text?: string;
}

const Blank: React.FC<BlankProps> = ({ text }) => {
  if (!text || text.length === 0) {
    text = "LOADING...";
  }
  return (
    <div
      className="fixed w-full h-screen z-30 bg-black opacity-50 flex items-center justify-center inset-0"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="text-white text-2xl"> {text} </div>
    </div>
  );
};

export default Blank;
