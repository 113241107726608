import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { GTag } from "@deptno/gtag-react";
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {
  Teacher,
  ReportRead,
  Signup,
  Login,
  Home,
  Test,
  SignupSuccess,
  Mypage,
  MypageInfo,
  Statistics,
  Notices,
  NoticeDetail,
  VerifyAccount,
  ResetPasswordSend,
  ResetPassword,
} from "./pages";
import "./assets/main.css";
import { API_URL } from "./API_URL";

const App: React.FC = () => {
  const httpLink = createHttpLink({
    uri: `${API_URL}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("token");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `JWT ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter forceRefresh={true}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/test" component={Test} />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <Route path="/report" component={ReportRead} />
          <Route path="/teacher" component={Teacher} />
          <Route path="/signup-success" component={SignupSuccess} />
          <Route exact path="/mypage" component={Mypage} />
          <Route path="/mypage/info" component={MypageInfo} />
          <Route path="/statistics" component={Statistics} />
          <Route exact path="/notices" component={Notices} />
          <Route path="/notices/:id" component={NoticeDetail} />
          <Route path="/verify/:token" component={VerifyAccount} />
          <Route path="/reset-password" component={ResetPasswordSend} />
          <Route path="/reset/:token" component={ResetPassword} />
        </Switch>
        <GTag id={"G-Y1EFLS53MB"} />
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
