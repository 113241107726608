import { gql } from "@apollo/client";

export const GET_ME = gql`
  query {
    me {
      id
      name
      nickname
      phone
      email
      userType
    }
  }
`;

export const GET_DETAIL_ME = gql`
  query {
    me {
      id
      name
      nickname
      phone
      email
      userType
      attendees {
        id
        studentNumber
        klass {
          id
          year
          grade
          klassNumber
          school {
            id
            name
          }
        }
      }
    }
  }
`;

export const TOKEN_AUTH = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      success
      errors
      unarchiving
      token
      refreshToken
      unarchiving
      user {
        id
        name
      }
    }
  }
`;

export const SIGNUP_AUTH = gql`
  mutation register(
    $email: String!
    $username: String!
    $nickname: String!
    $phone: String!
    $password1: String!
    $password2: String!
    $userType: Int!
    $name: String!
  ) {
    register(
      email: $email
      username: $username
      nickname: $nickname
      phone: $phone
      password1: $password1
      password2: $password2
      userType: $userType
      name: $name
    ) {
      success
      errors
      token
      refreshToken
    }
  }
`;

export const SIGNUP_ATTENDEE = gql`
  mutation signupAttendee(
    $userEmail: String!
    $year: String!
    $grade: Int!
    $klassNumber: Int!
    $studentNumber: Int!
    $school: String!
  ) {
    signupAttendee(
      userEmail: $userEmail
      year: $year
      grade: $grade
      klassNumber: $klassNumber
      studentNumber: $studentNumber
      school: $school
    ) {
      attendee {
        state
      }
    }
  }
`;

export const CREATE_REPORT = gql`
  mutation(
    $image: String!
    $comment: String!
    $date: Date!
    $userEmail: String!
    $klassId: String!
    $scores: [ScoreInput]!
  ) {
    createReport(
      image: $image
      comment: $comment
      date: $date
      userEmail: $userEmail
      klassId: $klassId
      scores: $scores
    ) {
      report {
        id
        image
      }
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation(
    $id: String!
    $image: String!
    $comment: String!
    $date: Date!
    $scores: [UpdateScoreInput]!
  ) {
    updateReport(
      id: $id
      image: $image
      comment: $comment
      date: $date
      scores: $scores
    ) {
      report {
        id
        image
        comment
        date
      }
      scores {
        subject {
          name
        }
        hour
        page
        minute
      }
    }
  }
`;

export const IMAGE_UPLOAD = gql`
  mutation ImageUpload($image: Upload!) {
    imageUpload(image: $image) {
      ok
      error
    }
  }
`;

export const GET_REPORT = gql`
  query($email: String!, $startday: Date!) {
    reportsByEmail(email: $email, startday: $startday) {
      id
      image
      comment
      date
      feedback
      klass {
        id
        teacher {
          name
        }
      }
      scores {
        id
        page
        hour
        minute
        subject {
          name
        }
      }
    }
    allSubjects {
      name
    }
    me {
      attendees {
        klass {
          id
        }
      }
    }
    getRecentNotice {
      id
      image
      createdAt
    }
  }
`;

export const GET_ADMIN = gql`
  query {
    allKlasses {
      year
      grade
      klassNumber
      attendees {
        studentNumber
        user {
          name
        }
        report {
          id
          comment
          image
          feedback
          date
        }
      }
    }
  }
`;

export const GET_TEACHER = gql`
  query {
    me {
      klasses {
        year
        grade
        klassNumber
        attendees {
          studentNumber
          user {
            name
            email
          }
          report {
            id
            comment
            image
            feedback
            date
          }
        }
      }
    }
  }
`;

export const UPDATE_REPORT_FEEDBACK = gql`
  mutation($id: String!, $feedback: String!) {
    updateReportFeedback(id: $id, feedback: $feedback) {
      report {
        id
        feedback
      }
    }
  }
`;

export const GET_SIGNUP = gql`
  query {
    allKlasses {
      year
      grade
      klassNumber
    }
    allSchools {
      id
      name
    }
  }
`;

export const GET_STATISTICS = gql`
  query($email: String!, $grade: Int!, $startday: Date!) {
    reportsByEmailByMonth(email: $email, startday: $startday) {
      scores {
        page
        hour
        minute
        subject {
          name
        }
      }
    }
    reportsByGradeByMonth(grade: $grade, startday: $startday) {
      date
      user {
        email
      }
      scores {
        page
        hour
        minute
        subject {
          name
        }
      }
    }
    allSubjects {
      name
    }
  }
`;

export const GET_NOTICES_TITLE = gql`
  query {
    allNotices {
      id
      title
    }
  }
`;

export const GET_NOTICE_BY_ID = gql`
  query($id: Int) {
    getNotice(id: $id) {
      title
      content
      createdAt
    }
  }
`;

export const UPDATE_USER = gql`
  mutation(
    $name: String!
    $nickname: String!
    $userEmail: String!
    $phone: String!
    $attendees: JSONString!
    $schoolId: Int
  ) {
    updateUser(
      name: $name
      nickname: $nickname
      userEmail: $userEmail
      phone: $phone
      attendees: $attendees
      schoolId: $schoolId
    ) {
      user {
        name
      }
    }
  }
`;

export const VERIFY_ACCOUNT = gql`
  mutation($token: String!) {
    verifyAccount(token: $token) {
      success
      errors
    }
  }
`;

export const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;

export const PASSWORD_RESET = gql`
  mutation($token: String!, $newPassword1: String!, $newPassword2: String!) {
    passwordReset(
      token: $token
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
      errors
    }
  }
`;
