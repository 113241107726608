import React from "react";
import Linkify from "react-linkify";

import { User, Report, Score, Subject } from "../../interfaces/model/index";
import "./index.css";

import { Label, Button } from "../../components";
import { API_URL } from "../../API_URL";

interface ReportReadProps {
  user: User;
  report: Report;
  scores: Record<string, Score>;
  subjects: Subject[];
  moveToUpdate: () => void;
}

const ReportRead: React.FC<ReportReadProps> = ({
  user,
  report,
  scores,
  subjects,
  moveToUpdate,
}) => {
  return (
    <>
      {report?.feedback ? (
        <div
          className="rounded-xl bg-orange-5 text-orange-2 text-center text-lg py-4 font-gsans-medium mt-4 font-light px-4"
          id="feedback_space"
        >
          <h1 className="text-gray-2 mb-2">
            {report.klass?.teacher?.name} 선생님
          </h1>
          <Linkify>{report.feedback}</Linkify>
        </div>
      ) : (
        <div className="rounded-xl bg-orange-5 text-orange-2 text-center text-lg py-4 font-gsans-medium mt-4 font-light">
          오늘의 스터디 완료! <br />
          선생님의 피드백을 기다려주세요.
        </div>
      )}
      <div className="relative w-full h-0 overflow-hidden pb-3/5 mt-8">
        {report?.image ? (
          <div
            className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover rounded-lg"
            style={{
              backgroundImage: `url(${API_URL}${report.image})`,
            }}
          ></div>
        ) : (
          <div className="absolute top-0 left-0 w-full h-full rounded-2xl border-4 border-dashed border-gray-5">
            <div className="w-full h-full flex justify-center items-center text-center">
              <div className="font-arita text-lg text-gray-3 pt-4">
                오늘의 플래너를 업로드해주세요.
                <img className="mx-auto mt-6" src="img/upload-btn.svg" />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="w-full mt-8">
        <Label hfor="comment">오늘의 한마디</Label>
        <div className="font-gsans-medium mt-1 whitespace-pre-line break-all break-words">
          {report?.comment ? (
            report.comment
          ) : (
            <div className="text-gray-4">남긴 코멘트가 없습니다.</div>
          )}
        </div>
      </div>

      <div className="w-full mt-8">
        <Label>오늘 공부량</Label>
        <div className="block mt-2">
          {subjects.map((el: Subject) => (
            <div
              className="inline-block w-48% mx-1% my-2 text-sm sh:text-base"
              key={el.name}
            >
              <div className="flex items-center">
                <div className="py-4 px-3 rounded-xl bg-orange-5 text-orange-1 text-lg sh:text-xl flex items-center justify-center font-gsans-bold">
                  {el.name}
                </div>
                <div className="ml-3">
                  {scores[el.name] ? (
                    <>
                      <div>
                        <span className="font-gsans-bold">
                          {scores[el.name].page}
                        </span>
                        <span className="font-gsans-medium">쪽</span>
                      </div>
                      <div>
                        <span className="font-gsans-bold">
                          {scores[el.name].hour}:
                          {String(scores[el.name].minute).length === 1
                            ? "0" + scores[el.name].minute
                            : scores[el.name].minute}
                        </span>{" "}
                        <span className="font-gsans-medium">시간</span>
                      </div>
                    </>
                  ) : (
                    <div className="font-gsans-medium">휴식 (･ᴥ･)</div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <Button
          className="mt-20 bg-gray-2 text-white mb-20"
          onClick={moveToUpdate}
        >
          플래너 수정하기
        </Button>
      </div>
    </>
  );
};

export default ReportRead;
