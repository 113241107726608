import React, { useState } from "react";
import { User, Report, Score, Subject } from "../../interfaces/model/index";
import { useMutation } from "@apollo/client";
import { UPDATE_REPORT } from "../../query/index";
import "./index.css";

import {
  Input,
  Label,
  Button,
  Textarea,
  Dropzone,
  Blank,
} from "../../components";

import { API_URL } from "../../API_URL";

interface ReportUpdateInput {
  id: string;
  subject: string;
  page: number;
  hour: number;
  minute: number;
}

interface ReportUpdateProps {
  history: any;
  refetch: any;
  user: User;
  subjects: Subject[];
  date: string;
  report: Report;
  moveToRead: () => void;
}

const ReportUpdate: React.FC<ReportUpdateProps> = ({
  history,
  refetch,
  user,
  subjects,
  date,
  report,
  moveToRead,
}) => {
  const [image, setImage] = useState(report.image);
  const [comment, setComment] = useState(report ? report.comment : "");
  const [isLoading, setLoading] = useState(false);

  let scoreDefault: Record<string, ReportUpdateInput> = {};
  report?.scores.map((el: Score) => {
    scoreDefault[el.subject.name] = {
      id: el.id,
      subject: el.subject.name,
      page: el.page,
      hour: el.hour,
      minute: el.minute,
    };
  });

  const [scores, setScores] = useState<Record<string, ReportUpdateInput>>(
    scoreDefault,
  );

  const [updateReport] = useMutation(UPDATE_REPORT);

  function handleChangeScores(
    name: string,
    key: "page" | "hour" | "minute",
    value: number,
  ) {
    if (key === "hour") {
      if (value > 23) {
        value = 23;
      }
      if (value < 0) {
        value = 0;
      }
    }
    if (key === "minute") {
      if (value > 59) {
        value = 59;
      }
      if (value < 0) {
        value = 0;
      }
    }

    let newScores = JSON.parse(JSON.stringify(scores));
    if (newScores[name]) {
      newScores[name][key] = value;
    } else {
      newScores[name] = {
        id: "",
        subject: name,
        page: 0,
        hour: 0,
        minute: 0,
        ...{ [key]: value },
      };
    }
    setScores(newScores);
  }

  function uploadImg(acceptedFiles: any) {
    console.log(acceptedFiles);
    let formData = new FormData();
    formData.append("FILES", acceptedFiles[0]);
    formData.append("user_email", user.email);
    formData.append("date", date);
    setLoading(true);
    return fetch(`${API_URL}/upload`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((json: any) => {
        console.log(json);
        if (json?.success) {
          setImage(
            json?.image_url +
              "?time=" +
              new Date().getTime() +
              Math.floor(Math.random() * 10),
          );
        }
        setLoading(false);
      });
  }

  function handleClickSubmit(e: any) {
    e.preventDefault();
    if (image.length === 0) {
      alert("플래너 이미지를 올려 주세요!");
      return false;
    }
    setLoading(true);
    updateReport({
      variables: {
        id: report.id,
        image,
        comment,
        date,
        scores: Object.values(scores),
      },
    })
      .then((res) => {
        const { data } = res;
        if (data.updateReport.report) {
          refetch();
          moveToRead();
        } else {
          history.push("/login");
        }
        setLoading(false);
      })
      .catch((err) => {
        alert("오류가 발생했습니다. 잠시 후 다시 시도해 주세요!");
        setLoading(false);
      });
  }

  const handleDrop = (e: any) => {
    const image = e.dataTransfer.files;
    uploadImg(image).then((res) => {
      console.log(res);
    });
  };

  return (
    <>
      {isLoading ? <Blank /> : <></>}
      <div className="relative w-full h-0 overflow-hidden pb-3/5 mt-8">
        {image ? (
          <div
            className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover rounded-lg"
            style={{
              backgroundImage: `url(${API_URL}${image})`,
            }}
          >
            <Dropzone className="ml-4" onDrop={uploadImg}>
              <div className="rounded-lg bg-orange-2 text-white text-lg font-bold max-w-max py-2 px-4 mt-4">
                수정하기
              </div>
            </Dropzone>
          </div>
        ) : (
          <div className="absolute top-0 left-0 w-full h-full rounded-2xl border-4 border-dashed border-gray-5">
            <div
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={(e) => e.preventDefault()}
              onDrop={handleDrop}
              className="w-full h-full flex justify-center items-center text-center"
            >
              <Dropzone onDrop={uploadImg}>
                <div className="font-arita text-lg text-gray-3 pt-4">
                  오늘의 플래너를 업로드해주세요.
                  <img
                    className="mx-auto mt-6 cursor-pointer"
                    src="img/upload-btn.svg"
                  />
                </div>
              </Dropzone>
            </div>
          </div>
        )}
      </div>

      <div className="w-full mt-8">
        <Label hfor="comment">오늘의 한마디</Label>
        <Textarea
          id="comment"
          rows={2}
          value={comment}
          placeholder="오늘 공부는 어땠나요? &#13;&#10;코멘트를 함께 남겨보세요!"
          className="bg-gray-8 border-gray-7 font-gsans-medium"
          onChange={(e) => setComment(e.target.value)}
        />
      </div>

      <div className="w-full mt-8">
        <Label>오늘 공부량</Label>
        <div className="block mt-4">
          {subjects.map((el: Subject) => (
            <div className="inline-block w-48% mx-1% my-1" key={el.name}>
              <Label htmlFor="hangul_page" className="font-gsans-medium">
                {el.name}
              </Label>
              <div className="flex justify-between items-center">
                <Input
                  type="number"
                  id="hangul_page"
                  className="flex justify-between items-center mr-1 py-3 px-2 w-2/5"
                  inputClassName="w-7 text-right"
                  value={String(scores[el.name]?.page).replace(/^0+/, "")}
                  placeholder="0"
                  onChange={(e) =>
                    handleChangeScores(el.name, "page", Number(e.target.value))
                  }
                >
                  <span className="text-orange-1">쪽</span>
                </Input>
                <div className="flex justify-around items-center ml-1 py-3 px-2 rounded-lg bg-gray-8 w-3/5 border-2 border-gray-7 my-1 focus-within:border-orange-2">
                  <input
                    type="number"
                    id="hangul_hour"
                    className="outline-none w-9 bg-gray-8 flex items-center text-lg focus:bg-gray-8 placeholder-gray-4 text-right"
                    value={String(scores[el.name]?.hour).replace(/^0+/, "")}
                    placeholder="시간"
                    min="0"
                    max="23"
                    onChange={(e) =>
                      handleChangeScores(
                        el.name,
                        "hour",
                        Number(e.target.value),
                      )
                    }
                  />
                  :
                  <input
                    type="number"
                    id="hangul_minute"
                    className="outline-none w-9 bg-gray-8 flex items-center text-lg focus:bg-gray-8 placeholder-gray-4 text-right"
                    value={String(scores[el.name]?.minute).replace(/^0+/, "")}
                    placeholder="분"
                    min="0"
                    max="59"
                    onChange={(e) =>
                      handleChangeScores(
                        el.name,
                        "minute",
                        Number(e.target.value),
                      )
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <Button
          className="mt-20 bg-gray-2 text-white mb-20"
          onClick={handleClickSubmit}
        >
          플래너 제출하기
        </Button>
      </div>
    </>
  );
};

export default ReportUpdate;
