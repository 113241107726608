import teacher from "./teacher/index";
import reportReadComponent from "./report/index";
import signup from "./auth/signup";
import login from "./auth/login";
import home from "./home";
import test from "./test";
import signupSuccess from "./auth/signupSuccess";
import mypage from "./mypage/index";
import mypageinfo from "./mypage/info/index";
import notices from "./mypage/notice/index";
import noticeDetail from "./mypage/notice/detail";
import admin from "./teacher/admin";
import statistics from "./statistics/index";
import verifyAccount from "./auth/verifyAccount";
import resetPassword from "./auth/resetPassword";
import resetPasswordSend from "./auth/resetPasswordSend";

export const Teacher = teacher;
export const ReportRead = reportReadComponent;
export const Signup = signup;
export const Login = login;
export const Home = home;
export const Test = test;
export const SignupSuccess = signupSuccess;
export const Mypage = mypage;
export const MypageInfo = mypageinfo;
export const Admin = admin;
export const Statistics = statistics;
export const Notices = notices;
export const NoticeDetail = noticeDetail;
export const VerifyAccount = verifyAccount;
export const ResetPasswordSend = resetPasswordSend;
export const ResetPassword = resetPassword;
