import React, { TextareaHTMLAttributes } from "react";

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
}

const Textarea: React.FC<TextareaProps> = ({ className, ...props }) => {
  return (
    <div
      className={`p-4 rounded-lg w-full border-2 my-1 focus-within:border-orange-2 ${className}`}
    >
      <textarea
        className="outline-none w-full bg-transparent flex items-center focus:bg-transparent placeholder-gray-4 resize-none overflow-auto"
        {...props}
      />
    </div>
  );
};

export default Textarea;
