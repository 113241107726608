import React from "react";
import moment from "moment";
import { Link, RouteComponentProps } from "react-router-dom";

import { Header, Content, Footer } from "../../../components";
import { GET_NOTICE_BY_ID } from "../../../query/index";
import { useQuery } from "@apollo/client";

interface MatchParams {
  id: string;
}

const NoticeDetail: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  let { id } = match.params;
  const { loading, error, data } = useQuery(GET_NOTICE_BY_ID, {
    variables: {
      id,
    },
  });

  return (
    <div>
      <Header
        left={
          <Link to="/notices">
            <img src="../img/back.svg" alt="" />
          </Link>
        }
        middle={<>공지사항 상세</>}
      />
      <Content>
        <div className="w-full mt-2 mb-8 font-sfpro flex items-center justify-between">
          <div className="font-bold text-gray-1">{data?.getNotice?.title}</div>
          <div className="font-sm text-gray-1">
            {moment(data?.getNotice?.createdAt).format("YYYY.MM.DD")}
          </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data?.getNotice?.content }} />
      </Content>
      <Footer selected="mypage" relative_path="../" />
    </div>
  );
};

export default NoticeDetail;
