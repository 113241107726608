import React, { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  ref?: any;
}

const Button: React.FC<ButtonProps> = ({
  className,
  children,
  ref,
  ...props
}) => {
  return (
    <button
      className={`w-full rounded-lg text-center p-4 font-bold text-lg ${className}`}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
