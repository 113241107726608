import React, { useState } from "react";
import {
  User,
  Report as ReportType,
  Score,
} from "../../interfaces/model/index";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useMe } from "../../hooks/useMe";
import { GET_REPORT } from "../../query/index";
import moment from "moment";
import "./index.css";

import ReportRead from "./read";
import ReportCreate from "./create";
import ReportUpdate from "./update";

import { Header, Content, Footer, Blank } from "../../components";
import { API_URL } from "../../API_URL";

const ReportComponent: React.FC<RouteComponentProps> = ({ history }) => {
  const { state, user } = useMe();

  if (state === "error" || state === "login required") {
    return <Redirect to="/login"></Redirect>;
  }

  if (state === "loading") {
    return (
      <div className="fixed w-full h-screen z-30 bg-black opacity-50 flex items-center justify-center inset-0">
        <div className="text-white text-2xl"> LOADING... </div>
      </div>
    );
  }

  if (user.userType === "A_1" || user.userType === "A_2") {
    return <Redirect to="/teacher"></Redirect>;
  }

  return <Report user={user} history={history} />;
};

interface ReportProps {
  history: any;
  user: User;
}

const Report: React.FC<ReportProps> = ({ history, user }) => {
  const [weeksAgo, setWeeksAgo] = useState(0);

  let today = moment().subtract(weeksAgo, "weeks");
  let startDay = today.clone().startOf("isoWeek").subtract(1, "days");
  let endDay = today.clone().endOf("isoWeek").subtract(1, "days");

  let WeekString =
    startDay.format("YYYY.MM.DD") + " - " + endDay.format("YYYY.MM.DD");

  //data fetch
  let { loading, error, data, refetch }: any = useQuery(GET_REPORT, {
    variables: {
      email: user ? user.email : "",
      startday: startDay.format("YYYY-MM-DD"),
    },
  });

  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD"),
  );

  // const [selectedReport, setSelectedReport] = useState<Report | null>();

  const reports: Record<string, ReportType> = {};
  data?.reportsByEmail.map((el: ReportType) => {
    reports[el.date] = el;
  });

  const scores: Record<string, Score> = {};
  reports[selectedDate]?.scores.map((el: Score) => {
    scores[el.subject.name] = el;
  });

  const [readState, setReadState] = useState(true);

  const [isNoticeOpen, setNoticeOpen] = useState(true);

  const noticeOpen =
    !localStorage.getItem("noticeClose") && Boolean(data?.getRecentNotice);

  return (
    <div className="w-full box-border">
      {loading ? <Blank /> : <></>}
      {error ? <Blank text="접속 오류입니다. 다시 접속해 주세요!" /> : <></>}
      {noticeOpen ? (
        isNoticeOpen ? (
          <div
            className="fixed w-full h-screen z-30 bg-translucent flex items-center justify-center inset-0"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className="w-100%-8 max-w-128 h-max rounded-lg bg-white opacity-100 overflow-hidden">
              <div className="relative w-full h-0 overflow-hidden pb-100%">
                <div
                  className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover cursor-pointer"
                  style={
                    data?.getRecentNotice?.image
                      ? {
                          backgroundImage: `url(${API_URL}/media/${data.getRecentNotice.image})`,
                        }
                      : {}
                  }
                  onClick={() => {
                    if (
                      data?.getRecentNotice?.id &&
                      Number(data?.getRecentNotice?.id) !== NaN
                    ) {
                      history.push(`/notices/${data?.getRecentNotice?.id}`);
                    }
                  }}
                ></div>
              </div>
              <div className="flex items-center">
                <div
                  className="w-1/2 p-1 sl:p-4 border-r-2 border-gray-5 flex items-center justify-center cursor-pointer"
                  onClick={() => {
                    localStorage.setItem(
                      "noticeClose",
                      moment().format("YYYY-MM-DD"),
                    );
                    setNoticeOpen(false);
                  }}
                >
                  <div className="text-gray-2 font-sfpro">다시 보지않기</div>
                </div>
                <div
                  className="w-1/2 p-1 sl:p-4 flex items-center justify-center cursor-pointer"
                  onClick={() => setNoticeOpen(false)}
                >
                  <div className="text-gray-2 font-sfpro font-bold">닫기</div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      <Header middle={<img className="w-32" src="img/logo.svg" />} />

      <Content>
        <div className="flex items-center justify-between">
          <div
            className="cursor-pointer w-6 h-6 flex items-center justify-center"
            onClick={() => setWeeksAgo(weeksAgo + 1)}
          >
            <img src="img/left-arrow.svg" />
          </div>
          <div className="text-gray-1 font-gsans-medium">{WeekString}</div>
          <div
            className="cursor-pointer w-6 h-6 flex items-center justify-center"
            onClick={() => setWeeksAgo(weeksAgo - 1)}
          >
            <img src="img/right-arrow.svg" />
          </div>
        </div>

        <div className="flex justify-around mt-5">
          {[
            [0, "일"],
            [1, "월"],
            [2, "화"],
            [3, "수"],
            [4, "목"],
            [5, "금"],
            [6, "토"],
          ].map(([num, day]) => {
            let currentDate = moment(startDay)
              .add(num, "days")
              .format("YYYY-MM-DD");
            return (
              <div
                key={day}
                className={
                  currentDate !== moment().format("YYYY-MM-DD")
                    ? currentDate === selectedDate
                      ? "bg-orange-2 text-white rounded-xl text-center w-1/8 py-2 cursor-pointer"
                      : "bg-white-5 rounded-lg text-center w-1/8 py-2 cursor-pointer"
                    : currentDate === selectedDate
                    ? "text-white bg-orange-2 rounded-xl text-center w-1/8 py-2 cursor-pointer"
                    : "text-orange-1 rounded-lg text-center w-1/8 py-2 cursor-pointer"
                }
                onClick={(e) => {
                  setSelectedDate(currentDate);
                  setReadState(true);
                }}
              >
                <div>
                  {currentDate === moment().format("YYYY-MM-DD") ? "오늘" : day}
                </div>
                <div>{moment(startDay).add(num, "days").date()}</div>
                {reports[currentDate] ? (
                  <div
                    className={
                      reports[currentDate].feedback
                        ? "w-2 h-2 rounded bg-green-1 mx-auto"
                        : "w-2 h-2 rounded bg-orange-4 mx-auto"
                    }
                  ></div>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>

        {reports[selectedDate] ? (
          readState ? (
            <ReportRead
              user={user}
              report={reports[selectedDate]}
              scores={scores}
              subjects={data?.allSubjects ? data.allSubjects : []}
              moveToUpdate={() => setReadState(false)}
            />
          ) : (
            <ReportUpdate
              history={history}
              refetch={refetch}
              user={user}
              report={reports[selectedDate]}
              date={selectedDate}
              subjects={data?.allSubjects ? data.allSubjects : []}
              moveToRead={() => setReadState(true)}
            />
          )
        ) : (
          <ReportCreate
            history={history}
            refetch={refetch}
            user={user}
            date={selectedDate}
            klassId={data?.me?.attendees?.slice(-1)[0]?.klass?.id}
            subjects={data?.allSubjects ? data.allSubjects : []}
            moveToRead={() => setReadState(true)}
          />
        )}
      </Content>
      {/* footer */}
      <Footer selected="report" />
    </div>
  );
};

export default withRouter(ReportComponent);
