import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Button, Header, Content, Input, Label, Blank } from "../../components";
import { SEND_PASSWORD_RESET_EMAIL } from "../../query/index";
import { useMutation } from "@apollo/client";

const ResetPasswordSend: React.FC = () => {
  const [email, setEmail] = useState("");
  const [sendEmail] = useMutation(SEND_PASSWORD_RESET_EMAIL);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>();
  const [isSuccess, setSuccess] = useState(false);

  const handleSendEmail = () => {
    setLoading(true);
    sendEmail({
      variables: {
        email,
      },
    })
      .then((res) => {
        const { data } = res;
        setLoading(false);
        if (data?.sendPasswordResetEmail?.success) {
          setSuccess(true);
        } else if (data?.sendPasswordResetEmail?.errors) {
          setSuccess(false);
          setErrors(data.sendPasswordResetEmail.errors);
        } else {
          setSuccess(false);
        }
      })
      .catch((rej) => {
        setLoading(false);
        setSuccess(false);
      });
  };

  return (
    <div className="w-full box-border">
      {isLoading ? <Blank /> : <></>}
      <Header
        left={
          <Link to="/login">
            <div className="w-8 h-8 flex justify-center items-center">
              <img src="img/back.svg" alt="" />
            </div>
          </Link>
        }
        middle={<>비밀번호 변경</>}
      />
      <Content>
        <div className="w-full mt-4">
          <div className="mx-auto text-center font-bold font-spoqa text-xl">
            비밀번호 설정을 위해
            <br />
            이메일 주소를 인증해주세요.
          </div>
        </div>

        <div className="w-full mt-14">
          <Label hfor="email">이메일주소</Label>
          <Input
            id="email"
            placeholder="이메일주소를 입력해주세요"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
            disabled={isSuccess}
          />
          <div className="text-pink-1 mt-1">
            {!isSuccess && errors?.email.map((el: any) => el.message).join(" ")}
          </div>
        </div>
        <Button
          className={
            email && !isSuccess
              ? "mt-6 border border-black bg-white rounded-lg text-black"
              : "mt-6 border border-gray-7 bg-white text-gray-4"
          }
          disabled={!email || isSuccess}
          type="submit"
          onClick={handleSendEmail}
        >
          인증번호 보내기
        </Button>
        <div className="mt-3 text-gray-1 font-spoqa">
          {isSuccess ? "비밀번호 변경 이메일이 전송되었습니다!" : ""}
        </div>
      </Content>
    </div>
  );
};

export default ResetPasswordSend;
