import React from "react";
import { useQuery, gql } from "@apollo/client";

const Test: React.FC = () => {
  // const query = `{ allKlasses { year, grade, createdAt, updatedAt, school { name, region } } }`;

  // useEffect(() => {
  //   fetch("http://localhost:8000/test", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ query }),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setKlassData(data);
  //     });
  // }, []);

  const GET_ALL_KLASSES = gql`
    query {
      allKlasses {
        year
        grade
        createdAt
        updatedAt
        school {
          name
          region
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_ALL_KLASSES);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className="text-4xl">
      {/* Test
      <br />
      {JSON.stringify(klassData)} */}
      {JSON.stringify(data)}
    </div>
  );
};

export default Test;
