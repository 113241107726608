import React, { FC, InputHTMLAttributes } from "react";
import Label from "./Label";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  theme?: "brand-1" | "brand-2";
}

export const Checkbox: FC<CheckboxProps> = ({
  label,
  theme = "brand-1",
  ...props
}) => {
  const apple = Math.floor(Math.random() * 10).toString();
  return (
    <div className="flex items-center space-x-2">
      <input
        {...props}
        id={apple}
        type="checkbox"
        className={`focus:${theme} w-4 h-4 text-${theme} border-gray-300 rounded`}
      />
      {label && <Label htmlFor={apple}>{label}</Label>}
    </div>
  );
};
