import React from "react";
import { Attendee, User } from "../../../interfaces/model";
import { Button } from "../../../components";

interface InfoReadProps {
  user: User;
  moveToUpdate: () => void;
}

const InfoRead: React.FC<InfoReadProps> = ({ user, moveToUpdate }) => {
  const klass = user?.attendees?.slice(-1)[0]?.klass;
  const klasses = user?.attendees?.map((el) => el.klass);
  return (
    <>
      <div className="mt-4 mb-8">
        <div className="font-spoqa text-sm text-gray-3 mb-1">이름</div>
        <div className="font-gsans text-lg text-gray-2">{user?.name}</div>
      </div>
      <div className="mb-8">
        <div className="font-spoqa text-sm text-gray-3 mb-1">닉네임</div>
        <div className="font-gsans text-lg text-gray-2">{user?.nickname}</div>
      </div>
      <div className="mb-8">
        <div className="font-spoqa text-sm text-gray-3 mb-1">이메일</div>
        <div className="font-gsans text-lg text-gray-2">{user?.email}</div>
      </div>
      <div className="mb-8">
        <div className="font-spoqa text-sm text-gray-3 mb-1">전화번호</div>
        <div className="font-gsans text-lg text-gray-2">{user?.phone}</div>
      </div>
      <div className="mb-8">
        <div className="font-spoqa text-sm text-gray-3 mb-1">학교</div>
        <div className="font-gsans text-lg text-gray-2">
          {klass?.school?.name}
        </div>
      </div>
      {klasses?.map((el) => (
        <div className="w-full flex items-center justify-between">
          <div className="mb-8">
            <div className="font-spoqa text-sm text-gray-3 mb-1">년도</div>
            <div className="font-gsans text-lg text-gray-2">{el?.year}년도</div>
          </div>
          <div className="mb-8">
            <div className="font-spoqa text-sm text-gray-3 mb-1">반</div>
            <div className="font-gsans text-lg text-gray-2">
              {el?.grade}학년 {el?.klassNumber}반
            </div>
          </div>
          <div className="mb-8">
            <div className="font-spoqa text-sm text-gray-3 mb-1">번호</div>
            <div className="font-gsans text-lg text-gray-2">
              {
                user?.attendees?.filter(
                  (at: Attendee) => at.klass.id === el.id,
                )[0].studentNumber
              }
            </div>
          </div>
        </div>
      ))}

      <Button
        className="mt-4 bg-gray-2 text-white mb-20"
        onClick={moveToUpdate}
      >
        내 정보 수정하기
      </Button>
    </>
  );
};

export default InfoRead;
