import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { HorizontalBar } from "react-chartjs-2";

interface StackChartProps {
  data: Record<string, any>[];
  formatter: (value: any, context: any) => string;
  labelFormatter?: (value: any) => string;
}

interface labelPropsT {
  datasetIndex?: number;
  xLabel?: number;
  value?: string;
}
interface labelPropsD {
  datasets?: Record<string, string>[];
}

const StackChart: React.FC<StackChartProps> = ({
  data,
  formatter,
  labelFormatter,
}) => {
  const dataArr = {
    datasets: data.map((el) => {
      return {
        label: el.label,
        data: [el.value],
        backgroundColor: el.color,
      };
    }),
  };
  return (
    <div>
      <HorizontalBar
        data={dataArr}
        height={80}
        plugins={[ChartDataLabels]}
        options={{
          maintainAspectRatio: false,
          legend: { display: false },
          tooltips: {
            mode: "single",
            callbacks: {
              title: () => "",
              label: function (t: labelPropsT, d: labelPropsD) {
                const subject = d?.datasets
                  ? d.datasets[t?.datasetIndex ? t.datasetIndex : 0]?.label
                  : "";
                const value = t?.value ? Number(t.value) : 0;
                const formattedValue = labelFormatter
                  ? labelFormatter(value)
                  : formatter(value, {});
                return `${subject}: ${formattedValue}`;
              },
            },
          },
          scales: {
            xAxes: [
              {
                offset: false,
                stacked: true,
                gridLines: { display: false },
                ticks: {
                  display: false,
                  max: data
                    .map((el) => Number(el.value))
                    .reduce((acc, cur) => acc + cur, 0),
                },
              },
            ],
            yAxes: [
              {
                barThickness: 24,
                stacked: true,
                gridLines: {
                  display: false,
                  drawTicks: false,
                },
              },
            ],
          },
          plugins: {
            datalabels: {
              anchor: "center",
              align: "top",
              offset: 16,
              color: "#828282",
              font: { size: 13 },
              formatter,
            },
          },
        }}
      />
    </div>
  );
};
export default StackChart;
