import React, { useState } from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { Input, Label, Button, Header, Content } from "../../components";

import { TOKEN_AUTH } from "../../query/index";
import "./index.css";

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [emailError, setEmailError] = useState("");
  let [passwordError, setPasswordError] = useState("");

  const [tokenAuth, { data }] = useMutation(TOKEN_AUTH);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    try {
      tokenAuth({
        variables: {
          email: email,
          password: password,
        },
      }).then((res) => {
        const { data } = res;
        if (data.tokenAuth.success) {
          localStorage.setItem("token", data.tokenAuth.token);
          history.push("/report");
        } else {
          if (data.tokenAuth.errors) {
            if (data.tokenAuth.errors.nonFieldErrors) {
              setEmailError("아이디나 비밀번호가 틀립니다!");
              setPasswordError("아이디나 비밀번호가 틀립니다!");
            } else {
              setEmailError(data.tokenAuth.errors.email.message);
              setPasswordError(data.tokenAuth.errors.password.message);
            }
          }
        }
      });
    } catch (err) {
      setEmailError("오류가 발생했습니다. 다시 입력해 주세요");
    }
  };

  return (
    <div className="w-full box-border">
      <Header
        left={
          <Link to="/">
            <div className="w-8 h-8 flex justify-center items-center">
              <img src="img/back.svg" alt="" />
            </div>
          </Link>
        }
        middle={<>로그인</>}
      />

      <Content>
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <Label hfor="email">이메일주소</Label>
            <Input
              id="email"
              placeholder="이메일주소를 입력해주세요"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                console.log(e.target.value);
                setEmail(e.target.value);
              }}
            />
            <div className="text-pink-1 mt-1">{emailError}</div>
          </div>

          <div className="w-full mt-6">
            <Label hfor="password">비밀번호</Label>
            <Input
              type="password"
              id="password"
              placeholder="비밀번호를 입력해주세요"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="text-pink-1 mt-1">{passwordError}</div>
          </div>

          <div className="w-full mt-6">
            <Link to="/reset-password">
              <div className="text-gray-4 underline">
                비밀번호를 잃어버리셨나요?
              </div>
            </Link>
          </div>

          <Button className="mt-14 bg-orange-2 text-white" type="submit">
            로그인
          </Button>

          <Link to="/signup">
            <Button className="mt-4 bg-gray-7 text-gray-2">회원가입</Button>
          </Link>
        </form>
      </Content>
    </div>
  );
};

export default withRouter(Login);
