import React, { useState } from "react";
import {
  User,
  Report as ReportType,
  Score,
  Subject,
} from "../../interfaces/model/index";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDetailMe } from "../../hooks/useDetailMe";
import { GET_STATISTICS } from "../../query/index";
import moment from "moment";
import {
  Header,
  Content,
  Footer,
  StackChart,
  BarChart,
  Blank,
} from "../../components";

interface Dataset {
  label: string;
  data: number[];
  backgroundColor: string;
}

interface Data {
  labels: string[];
  datasets: Dataset[];
}

const StatisticsComponent: React.FC<RouteComponentProps> = ({ history }) => {
  const { state, user } = useDetailMe();

  if (state === "error" || state === "login required") {
    return <Redirect to="/login"></Redirect>;
  }

  if (state === "loading") {
    return (
      <div className="fixed w-full h-screen z-30 bg-black opacity-50 flex items-center justify-center inset-0">
        <div className="text-white text-2xl"> LOADING... </div>
      </div>
    );
  }

  if (user.userType === "A_1" || user.userType === "A_3") {
    return <Redirect to="/teacher"></Redirect>;
  }

  return <Statistics user={user} history={history} />;
};

interface StatisticsProps {
  history: any;
  user: User;
}

const Statistics: React.FC<StatisticsProps> = ({ user, history }) => {
  const [monthsAgo, setMonthsAgo] = useState(0);

  let today = moment().subtract(monthsAgo, "months");
  let startDay = today.clone().startOf("month");
  let endDay = today.clone().endOf("month");
  let WeekString = startDay.format("YYYY년 MM월");

  //data fetch
  let { loading, error, data, refetch }: any = useQuery(GET_STATISTICS, {
    variables: {
      email: user ? user.email : "",
      grade: user ? user.attendees?.slice(-1)[0]?.klass?.grade : 0,
      startday: startDay.format("YYYY-MM-DD"),
    },
  });

  const subjects: Subject[] = data?.allSubjects;
  const allReports: ReportType[] = data?.reportsByGradeByMonth;
  const myReports: ReportType[] = data?.reportsByEmailByMonth;

  let myReportsTimeData: Record<string, any>[] = [];
  let myReportsPageData: Record<string, any>[] = [];
  let gradeReportsTimeData: Record<string, any>[] = [];
  let gradeReportsPageData: Record<string, any>[] = [];
  let klassesTimeData: Data;
  let allKlassesAvgTimeData: number[] = [];
  let top20AvgTimeData: number[] = [];
  let myAvgTimeData: number[] = [];
  let klassesPageData: Data;
  let allKlassesAvgPageData: number[] = [];
  let top20AvgPageData: number[] = [];
  let myAvgPageData: number[] = [];

  let colorsArr = [
    "#FF922B",
    "#FCC419",
    "#83C17D",
    "#F8EFE4",
    "#88B2F8",
    "#ADFFD2",
    "#2A5B72",
    "#bb7dc1",
    "#88eaf8",
    "#f888b2",
    "#9688F8",
  ];

  subjects?.map((subject: Subject, i: number) => {
    let sumTime = 0;
    let sumPage = 0;
    let count = 0;
    myReports?.map((report: ReportType) => {
      report.scores?.map((score: Score) => {
        if (score.subject.name === subject.name) {
          sumTime += score.hour * 60 + score.minute;
          sumPage += score.page;
          count++;
        }
      });
    });
    if (count === 0) {
      count = 1;
    }
    myReportsTimeData.push({
      label: subject.name,
      value: Math.ceil(sumTime / count),
      color: colorsArr[i],
    });
    myReportsPageData.push({
      label: subject.name,
      value: (sumPage / count).toFixed(1),
      color: colorsArr[i],
    });
    myAvgTimeData.push(Math.ceil(sumTime / count));
    myAvgPageData.push(parseFloat((sumPage / count).toFixed(1)));

    let sumGradeTime = 0;
    let sumGradePage = 0;
    let gradeCount = 0;
    let klassTimes: number[] = [];
    let klassPages: number[] = [];
    let klassCount = 0;
    allReports?.map((report: ReportType) => {
      report.scores?.map((score: Score) => {
        if (score.subject.name === subject.name) {
          sumGradeTime += score.hour * 60 + score.minute;
          sumGradePage += score.page;
          gradeCount++;
          klassTimes.push(score.hour * 60 + score.minute);
          klassPages.push(score.page);
          klassCount++;
        }
      });
    });
    if (gradeCount === 0) {
      gradeCount = 1;
    }
    if (klassCount === 0) {
      klassCount = 1;
    }
    gradeReportsTimeData.push({
      label: subject.name,
      value: Math.ceil(sumGradeTime / gradeCount),
      color: colorsArr[i],
    });
    gradeReportsPageData.push({
      label: subject.name,
      value: (sumGradePage / gradeCount).toFixed(1),
      color: colorsArr[i],
    });

    let sumKlassTime = klassTimes.reduce((a, b) => a + b, 0);
    let sumTop20Time = klassTimes
      .sort((a, b) => b - a)
      .slice(0, Math.ceil(klassCount / 5))
      .reduce((a, b) => a + b, 0);
    top20AvgTimeData.push(Math.ceil(sumTop20Time / Math.ceil(klassCount / 5)));
    let sumKlassPage = klassPages.reduce((a, b) => a + b, 0);
    let sumTop20Page = klassPages
      .sort((a, b) => b - a)
      .slice(0, Math.ceil(klassCount / 5))
      .reduce((a, b) => a + b, 0);
    top20AvgPageData.push(Math.ceil(sumTop20Page / Math.ceil(klassCount / 5)));

    allKlassesAvgTimeData.push(Math.ceil(sumKlassTime / klassCount));
    allKlassesAvgPageData.push(
      parseFloat((sumKlassPage / klassCount).toFixed(1)),
    );
  });

  klassesTimeData = {
    labels: subjects?.map((el) => el.name),
    datasets: [
      {
        label: "학년 평균값",
        data: allKlassesAvgTimeData,
        backgroundColor: "#CED4DA",
      },
      {
        label: "상위 20% 평균",
        data: top20AvgTimeData,
        backgroundColor: "#FFC542",
      },
      {
        label: "내 학습시간",
        data: myAvgTimeData,
        backgroundColor: "#EE853A",
      },
    ],
  };

  klassesPageData = {
    labels: subjects?.map((el) => el.name),
    datasets: [
      {
        label: "학년 평균값",
        data: allKlassesAvgPageData,
        backgroundColor: "#CED4DA",
      },
      {
        label: "상위 20% 평균",
        data: top20AvgPageData,
        backgroundColor: "#FFC542",
      },
      {
        label: "내 학습분량",
        data: myAvgPageData,
        backgroundColor: "#EE853A",
      },
    ],
  };

  return (
    <div className="w-full box-border">
      {loading ? <Blank /> : <></>}
      {error ? <Blank text="접속 오류입니다. 다시 접속해 주세요!" /> : <></>}
      <Header middle={<>통계</>} />

      <Content>
        <div className="flex items-center justify-between">
          <div
            className="cursor-pointer w-6 h-6 flex items-center justify-center"
            onClick={() => setMonthsAgo(monthsAgo + 1)}
          >
            <img src="img/left-arrow.svg" />
          </div>
          <div className="text-gray-1 font-gsans-medium">{WeekString}</div>
          <div
            className="cursor-pointer w-6 h-6 flex items-center justify-center"
            onClick={() => setMonthsAgo(monthsAgo - 1)}
          >
            <img src="img/right-arrow.svg" />
          </div>
        </div>
        <div className="w-full text-center text-gray-4 mt-3">
          * 1시간 마다 데이터가 업데이트 됩니다.
        </div>

        <div className="text-gray-2 font-gsans-medium mt-6 text-xl">
          학습 균형 분석
        </div>
        <div className="mt-5 flex items-center">
          {subjects?.map((el: Subject, i: number) => (
            <div className="mr-4">
              <div
                className="w-4 h-4 rounded inline-block mr-2"
                style={{ backgroundColor: colorsArr[i] }}
              />
              <div className="inline-block transform -translate-y-1">
                {el.name.slice(0, 1)}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-4 border-2 border-gray-7 rounded-xl">
          <div className="mt-3 px-4 font-gsans-medium text-gray-2 ">
            학년 평균 시간
          </div>
          <div className="border border-gray-7 w-full my-3" />
          <div className="px-3">
            {allReports?.length ? (
              <StackChart
                data={gradeReportsTimeData}
                formatter={(value: any, context: any) => {
                  if (value === 0) return "";
                  let hour = Math.floor(value / 60);
                  let minute = (value - hour * 60).toString();
                  if (minute.length === 1) {
                    minute = "0" + minute;
                  } else if (minute.length === 0) {
                    minute = "00";
                  }
                  return `${hour}:${minute}`;
                }}
                labelFormatter={(value: any) => {
                  if (value === 0) return "";
                  let hour = Math.floor(value / 60);
                  let minute = value - hour * 60;
                  if (hour === 0) {
                    return `${minute}분`;
                  } else if (minute === 0) {
                    return `${hour}시간`;
                  } else {
                    return `${hour}시간 ${minute}분`;
                  }
                }}
              />
            ) : (
              <div className="w-full h-full flex justify-center items-center py-5">
                <div className="text-gray-3">데이터가 없습니다.</div>
              </div>
            )}
          </div>
          <div className="border border-gray-7 w-full mb-4" />
          <div className="mt-3 px-4 font-gsans-medium text-orange-1 font-bold">
            <img src="img/statistics-star.svg" alt="" className="inline" /> 나의
            학습 시간
          </div>
          <div className="border border-gray-7 w-full my-3" />
          <div className="px-3">
            {myReports?.length ? (
              <StackChart
                data={myReportsTimeData}
                formatter={(value: any, context: any) => {
                  if (value === 0) return "";
                  let hour = Math.floor(value / 60);
                  let minute = (value - hour * 60).toString();
                  if (minute.length === 1) {
                    minute = "0" + minute;
                  } else if (minute.length === 0) {
                    minute = "00";
                  }
                  return `${hour}:${minute}`;
                }}
                labelFormatter={(value: any) => {
                  if (value === 0) return "";
                  let hour = Math.floor(value / 60);
                  let minute = value - hour * 60;
                  if (hour === 0) {
                    return `${minute}분`;
                  } else if (minute === 0) {
                    return `${hour}시간`;
                  } else {
                    return `${hour}시간 ${minute}분`;
                  }
                }}
              />
            ) : (
              <div className="w-full h-full flex justify-center items-center py-5">
                <div className="text-gray-3">데이터가 없습니다.</div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-4 border-2 border-gray-7 rounded-xl">
          <div className="mt-3 px-4 font-gsans-medium text-gray-2 ">
            학년 평균 분량
          </div>
          <div className="border border-gray-7 w-full my-3" />
          <div className="px-3">
            {allReports?.length ? (
              <StackChart
                data={gradeReportsPageData}
                formatter={(value: any, context: any) =>
                  value === 0 ? "" : `${value}쪽`
                }
              />
            ) : (
              <div className="w-full h-full flex justify-center items-center py-5">
                <div className="text-gray-3">데이터가 없습니다.</div>
              </div>
            )}
          </div>
          <div className="border border-gray-7 w-full mb-4" />
          <div className="mt-3 px-4 font-gsans-medium text-orange-1 font-bold">
            <img src="img/statistics-star.svg" alt="" className="inline" /> 나의
            학습 분량
          </div>
          <div className="border border-gray-7 w-full my-3" />
          <div className="px-3">
            {myReports?.length ? (
              <StackChart
                data={myReportsPageData}
                formatter={(value: any, context: any) =>
                  value === 0 ? "" : `${value}쪽`
                }
              />
            ) : (
              <div className="w-full h-full flex justify-center items-center py-5">
                <div className="text-gray-3">데이터가 없습니다.</div>
              </div>
            )}
          </div>
        </div>
        <div className="text-gray-2 font-gsans-medium mt-7 mb-2 text-xl">
          학습 시간
        </div>
        <BarChart
          data={klassesTimeData}
          formatter={(value: any, context: any) => {
            if (value === 0) return "";
            let hour = Math.floor(value / 60);
            let minute = (value - hour * 60).toString();
            if (minute.length === 1) {
              minute = "0" + minute;
            } else if (minute.length === 0) {
              minute = "00";
            }
            return `${hour}:${minute}`;
          }}
          labelFormatter={(value: any) => {
            if (value === 0) return "";
            let hour = Math.floor(value / 60);
            let minute = value - hour * 60;
            if (hour === 0) {
              return `${minute}분`;
            } else if (minute === 0) {
              return `${hour}시간`;
            } else {
              return `${hour}시간 ${minute}분`;
            }
          }}
          stepSize={30}
        />
        <div className="text-gray-2 font-gsans-medium mt-7 mb-2 text-xl">
          학습 분량
        </div>
        <BarChart
          data={klassesPageData}
          formatter={(value: any, context: any) =>
            value === 0 ? "" : `${value}쪽`
          }
          stepSize={10}
        />
        <div className="mb-20"></div>
      </Content>
      <Footer selected="statistics" />
    </div>
  );
};

export default withRouter(StatisticsComponent);
